import { FC } from 'react';
import { IEntityListItemProps } from '../../../core/interfaces/entity-list-item-props.interface';
import { IActivity } from '../models/activity.model';
import { ActivityListItemTemplate } from './ActivityListItemTemplate';

export const ActivityListItem: FC<IEntityListItemProps<IActivity>> = ({
    entity: activity,
}) => {
    return (
        <ActivityListItemTemplate
            authorName={activity.author_id.name}
            authorEmail={activity.author_id.email}
            messageUpdatedAt={activity.updated_at}
        />
    );
};
