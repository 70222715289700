import { TableCell } from '@mui/material';
import { asDateString } from 'core/helpers/asDateString';
import { FC } from 'react';

interface IActivityListItemTemplate {
    authorName?: string;
    authorEmail?: string;
    messageUpdatedAt?: Date;
}

export const ActivityListItemTemplate: FC<IActivityListItemTemplate> = ({
    messageUpdatedAt,
    authorName,
    authorEmail,
}) => {
    return (
        <>
            <TableCell>{authorName}</TableCell>
            <TableCell>{authorEmail}</TableCell>
            <TableCell>
                {messageUpdatedAt &&
                    asDateString(messageUpdatedAt, {
                        format: 'date',
                    })}
            </TableCell>
            <TableCell>
                {messageUpdatedAt &&
                    asDateString(messageUpdatedAt, {
                        format: 'time',
                    })}
            </TableCell>
        </>
    );
};
