import { Snackbar, SnackbarProps } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useActionStatus } from 'core/hooks/action-status.hook';
import { useContext, useMemo, useState } from 'react';
import { ValidTranslationKeys } from 'react-i18next';
import { MultiLanguageSupportContext } from './MultiLanguageSupportProvider';

export const ActionStatuses = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
        open: false,
        autoHideDuration: 3000,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        },
    });

    const handleSnackbarClick = () => {
        setSnackbarProps({ ...snackbarProps, open: true });
    };

    const handleSnackbarClose = () => {
        setSnackbarProps({ ...snackbarProps, open: false });
    };

    const actionStatus = useActionStatus();

    const message: keyof ValidTranslationKeys | null = useMemo(() => {
        if (!actionStatus) {
            return null;
        }

        handleSnackbarClick();

        return `${actionStatus.action}-${actionStatus.type}-${
            actionStatus.success ? 'success' : 'failure'
        }` as keyof ValidTranslationKeys;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionStatus]);

    return (
        <Snackbar {...snackbarProps} onClose={handleSnackbarClose}>
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleSnackbarClose}
                severity={
                    !actionStatus
                        ? 'info'
                        : actionStatus.success
                          ? 'success'
                          : 'error'
                }
            >
                {message && t(message)}
            </MuiAlert>
        </Snackbar>
    );
};
