import { Component, ReactNode } from 'react';

export interface ErrorBoundaryProps {
    [key: string]: any;
    children?: ReactNode;
}

export class ErrorBoundary extends Component<
    ErrorBoundaryProps,
    { error?: any }
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: any): { error: any } {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    componentDidCatch(error: any, errorInfo: any): void {
        // You can also log the error to an error reporting service
        console.warn(error, errorInfo);
    }

    render(): ReactNode {
        const { error } = this.state;

        if (error) {
            // You can render any custom fallback UI
            return <h1>{error.message ?? `Something went wrong.`}</h1>;
        }

        return this.props.children;
    }
}
