import Alert, { AlertProps } from '@mui/material/Alert';
import { ReactNode } from 'react';
import { AlertTitle } from '@mui/material';

interface Props {
    severity?: AlertProps['severity'];
    icon?: ReactNode;
    text: string;
    title: string;
}

export const UserAlertBox = ({
    severity = 'success',
    icon = undefined,
    text,
    title,
}: Props) => {
    return (
        <Alert icon={icon} severity={severity}>
            <AlertTitle>{title}</AlertTitle>
            {text}
        </Alert>
    );
};
