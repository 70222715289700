/** @jsxImportSource @emotion/react */
import {
    Box,
    Button,
    Container,
    css,
    IconButton,
    InputAdornment,
    TextField,
    Theme,
    Typography,
} from '@mui/material';

import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import NeedHelpMail from 'core/components/shared/micro/NeedHelpMail';
import { passwordValidation } from 'core/validations';
import { Form, Formik, FormikProps } from 'formik';
import { FC, useContext, useState } from 'react';
import LogoWithText from 'styles/material-ui/elements/logo-with-text';
import * as Yup from 'yup';
import { INewResetPasswordCredentials } from '../../../resetpassword/models/new.password.model';

const initialValues: INewResetPasswordCredentials = {
    password: '',
};

const styles = {
    paper: (theme: Theme) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: ${theme.spacing(22)};
    `,
    form: () => css`
        width: 100%;
    `,
};

interface LoginFormProps {
    onSubmitClick: (data: INewResetPasswordCredentials) => void;
}

export const AcitavteAccountForm: FC<LoginFormProps> = ({ onSubmitClick }) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const [showPassword, setShowPassword] = useState(false);

    const validationSchema = Yup.object().shape({
        password: passwordValidation,
    });

    return (
        <Container maxWidth="sm">
            <div css={styles.paper}>
                <LogoWithText />

                <Box mt={16}>
                    <Typography variant="h1">
                        {t('activate-account')}
                    </Typography>
                </Box>

                <Formik
                    initialValues={initialValues}
                    onSubmit={(
                        credentials: INewResetPasswordCredentials,
                        actions
                    ) => {
                        onSubmitClick(credentials);
                        actions.resetForm();
                    }}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {(props: FormikProps<INewResetPasswordCredentials>) => {
                        const {
                            values,
                            errors,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            submitForm,
                        } = props;
                        return (
                            <Form css={styles.form}>
                                <Box mt={8}>
                                    <TextField
                                        label={t('password-placeholder')}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        autoComplete="password"
                                        autoFocus
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        error={!!errors.password}
                                        helperText={t('enter-password')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword
                                                            )
                                                        }
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Typography
                                        className="MuiTypography-badge"
                                        color="textSecondary"
                                    >
                                        {t('password-hint')}
                                    </Typography>
                                </Box>
                                <Box mt={8} mb={6}>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        disableElevation
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        {t('set-my-password')}
                                    </Button>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <NeedHelpMail />
        </Container>
    );
};
