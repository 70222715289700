/** @jsxImportSource @emotion/react */
import { Box, css, Theme, Tooltip, Typography } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { useSession } from 'core/hooks/session.hook';
import { USER_ROLE } from 'modules/users/models/user.model';
import { FC, useCallback, useContext, useMemo } from 'react';
import { ValidTranslationKeys } from 'react-i18next';

const styles = {
    root: (theme: Theme) => css`
        display: flex;
        flex-direction: row;
        ${theme.breakpoints.down('sm')} {
            flex-wrap: wrap;
        }
    `,
    input: (theme: Theme) => css`
        position: absolute;
        opacity: 0;
        z-index: 100;
        &:checked + span {
            color: ${theme.palette.background.default};
            background-color: #172b4d;
        }
        &:disabled + span {
            cursor: not-allowed;
        }
        &:not(:disabled):checked:hover + span {
            background-color: ${theme.palette.primary.light};
            cursor: default;
        }
    `,
    label: (theme: Theme) => css`
        ${theme.breakpoints.down('sm')} {
            width: calc(50% - ${theme.spacing(2)});
            margin-right: ${theme.spacing(4)};
            margin-bottom: ${theme.spacing(4)};
            &:nth-of-type(2n + 2) {
                margin-right: 0;
            }
        }
    `,
    labelSpan: (theme: Theme) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid;
        border-color: ${theme.palette.text.secondary};
        height: 104px;
        width: 120px;
        margin-right: ${theme.spacing(2)};
        color: ${theme.palette.text.secondary};
        cursor: pointer;
        border-radius: ${theme.spacing(1)};

        ${theme.breakpoints.down('sm')} {
            height: 80px;
            width: 100%;
        }
        &:hover + input:not(:disabled) {
            color: ${theme.palette.primary.light};
            border-color: ${theme.palette.primary.light};
        }
    `,
    icon: css`
        font-size: 24px;
    `,
};

interface IUserRoleRadioButtonConfig {
    title: keyof ValidTranslationKeys;
    value: USER_ROLE;
    label: keyof ValidTranslationKeys;
}

const userRoleRadioButtonConfigs: IUserRoleRadioButtonConfig[] = [
    {
        title: 'tooltip-project',
        value: USER_ROLE.PROJECT_ADMIN,
        label: 'project',
    },
    {
        title: 'tooltip-portfolio',
        value: USER_ROLE.PORTFOLIO_ADMIN,
        label: 'portfolio',
    },
    {
        title: 'tooltip-account',
        value: USER_ROLE.ACCOUNT_ADMIN,
        label: 'account',
    },
    {
        title: 'tooltip-super-admin',
        value: USER_ROLE.SUPER_ADMIN,
        label: 'super-admin',
    },
];

const numberedRolesMap = Object.values(USER_ROLE)
    .reverse()
    .reduce((map, role, index) => map.set(role, index), new Map());

interface IUserRoleRadioButtonsProps {
    radioButtonValue: USER_ROLE;
    disabled?: boolean;
    onChangeHandler: (type: string) => void;
}

export const UserRoleRadioButtons: FC<IUserRoleRadioButtonsProps> = ({
    radioButtonValue,
    disabled,
    onChangeHandler,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeHandler(event.target.value);
    };

    const { t } = useContext(MultiLanguageSupportContext);

    const { me } = useSession();

    const canUpdateRole = useCallback(
        (role: USER_ROLE): boolean => {
            if (!me?.role) {
                return false;
            }

            if (
                numberedRolesMap.get(me.role) >
                numberedRolesMap.get(USER_ROLE.PORTFOLIO_ADMIN)
            ) {
                return (
                    numberedRolesMap.get(me.role) >= numberedRolesMap.get(role)
                );
            }

            return numberedRolesMap.get(me.role) > numberedRolesMap.get(role);
        },
        [me?.role]
    );

    const configs = useMemo(
        () =>
            userRoleRadioButtonConfigs.map((role) => ({
                ...role,
                enabled: canUpdateRole(role.value),
            })),
        [canUpdateRole]
    );

    return (
        <Box css={styles.root}>
            {configs.map(({ title, value, label, enabled }) => (
                <Tooltip title={t(title)} key={label}>
                    <label css={styles.label}>
                        <input
                            type="radio"
                            css={styles.input}
                            checked={radioButtonValue === value}
                            value={value}
                            name="user-type"
                            id={label}
                            onChange={handleChange}
                            disabled={disabled || !enabled}
                        />
                        <span css={styles.labelSpan}>
                            <FolderIcon css={styles.icon} />
                            <Typography
                                variant="overline"
                                style={{ textTransform: 'capitalize' }}
                            >
                                {t(label)}
                            </Typography>
                        </span>
                    </label>
                </Tooltip>
            ))}
        </Box>
    );
};
