/** @jsxImportSource @emotion/react */
import { Box, css, Link, Typography } from '@mui/material';
import { useContext } from 'react';
import { MultiLanguageSupportContext } from '../../MultiLanguageSupportProvider';
import { Theme } from '@mui/material';
const styles = {
    micro: (theme: Theme) => css`
        width: 100%;
        margin-bottom: ${theme.spacing(7)};
    `,
};

export default function NeedHelpMail() {
    const { t } = useContext(MultiLanguageSupportContext);

    return (
        <Box css={styles.micro} style={{ margin: '40px 0 20px 0' }}>
            <Typography display="block" align="center">
                {t('need-help')}
                <br />
                <Link href={'mailto:' + import.meta.env.VITE_APP_SUPPORT_EMAIL}>
                    {t('email-project-pulse-team')}
                </Link>
            </Typography>
        </Box>
    );
}
