import { createTheme } from '@mui/material/styles';

import { Theme as MaterialUITheme, Theme } from '@mui/material/styles';

declare module '@mui/styles' {
    interface DefaultTheme extends Theme {}
}

declare module '@emotion/react' {
    export interface Theme extends MaterialUITheme {}
}

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true; // removes the `xs` breakpoint
        sm: true;
        smm: true;
        md: true;
        lg: true;
        xl: true;
    }
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            smm: 768,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        // mode: 'light',
        primary: {
            main: '#5C36B0',
            light: '#754CCD',
            dark: '#4C289A',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#DFE1E6',
            light: '#F2EBFF',
            dark: '#CDBAF3',
            contrastText: '#381D72',
        },
        error: {
            main: '#DB340B',
            light: '#FF5029',
            dark: '#B32400',
            contrastText: '#FFEBE6',
        },
        warning: {
            main: '#FF961A',
            light: '#FFAD05',
            dark: '#FF8D05',
            contrastText: '#FFFAE6',
        },
        info: {
            main: '#0050C7',
            light: '#0061F5',
            dark: '#0745A1',
            contrastText: '#E6F0FF',
        },
        success: {
            main: '#008055',
            light: '#35B07C',
            dark: '#006B47',
            contrastText: '#E8FDF2',
        },
        background: {
            default: '#FFFFFF',
            paper: '#FAFBFC',
        },
        text: {
            primary: '#172B4D',
            secondary: '#6B778C',
            disabled: '#C1C7D0',
        },
        divider: '#DFE1E6',
    },
    typography: {
        fontFamily: 'Nunito Sans',
        h1: {
            fontWeight: 700,
            fontSize: '40px',
            lineHeight: '56px',
            letterSpacing: '-0.01em',
        },
        h2: {
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '48px',
            letterSpacing: '-0.01em',
        },
        h3: {
            fontWeight: 700,
            fontSize: '28px',
            lineHeight: '40px',
            letterSpacing: '-0.01em',
        },
        h4: {
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
            letterSpacing: '-0.01em',
        },
        h5: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '24px',
        },
        h6: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '24px',
        },
        subtitle1: {
            fontSize: 16,
            lineHeight: 1,
            letterSpacing: '-0.01em',
        },
        subtitle2: {
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.5,
        },
        body1: {
            fontSize: 16,
        },
        body2: {
            fontSize: 14,
            lineHeight: 1.2,
            letterSpacing: '-0.01em',
        },
        button: {
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.5,
            letterSpacing: '0.01em',
        },
        caption: {
            fontSize: 16,
            fontWeight: 300,
            lineHeight: 1.5,
            fontStyle: 'italic',
        },
        overline: {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.02em',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    // forms & inputs
                    '..MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
                        display: 'none',
                    },
                    // nav tabs (titlebar)
                    '.MuiTab-textColorInherit.Mui-selected': {
                        color: '#5C36B0',
                    },
                },
            },
        },
        // forms and inputs
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: '#6B778C',
                },
            },
        },
        // appbar
        MuiAppBar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: '#FFFFFF',
                    boxShadow: 'none',
                    borderTop: '0',
                    borderBottom: '1px solid #DFE1E6',
                },
            },
        },
        // buttons#FFEBE6
        MuiButton: {
            styleOverrides: {
                root: {
                    minHeight: 40,
                    padding: '12px 16px',
                    textTransform: 'none',
                    maxHeight: 48,
                    '&.MuiButton-colorWarn': {
                        color: '#DB340B',
                        backgroundColor: '#e0e0e0',
                        '&:hover': {
                            backgroundColor: '#FFC1B3',
                        },
                    },
                    '&.MuiButton-destructive': {
                        backgroundColor: '#DB340B',
                        color: '#FFEBE6',
                        '&:hover': {
                            backgroundColor: '#B22400',
                        },
                    },
                },
                outlined: {
                    padding: '12px 16px',
                    textTransform: 'none',
                    maxHeight: 48,
                },
                text: {
                    padding: '12px 16px',
                },
                iconSizeMedium: {
                    '& > *first-child': {
                        fontSize: '14px',
                    },
                },
                containedSizeSmall: {
                    padding: '12px,',
                    fontSize: '14px',
                },
                iconSizeSmall: {
                    '& > *first-child': {
                        fontSize: '14px',
                    },
                },
            },
        },
        // no required asterisk, label for optional fields needs to
        // be wrapped in optionalTextField helper function
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    display: 'none',
                },
            },
        },
        // extra typo variant "badge"

        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.MuiTypography-badge': {
                        fontSize: '12px',
                        lineHeigt: '16px',
                    },
                },
            },
        },
        // Custom Tabs
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.MuiTab-root': {
                        minWidth: 0,
                        padding: `12px 24px`,
                    },
                },
            },
        },
        // table styles
        MuiTable: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F4F5F7',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid #DFE1E6',

                    '&.MuiTableCell-actions': {
                        // width: '135px',
                        textAlign: 'right',
                    },
                },
            },
        },
    },
});

export default theme;
