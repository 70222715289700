import { FC } from 'react';
import { ResetPasswordForm } from '../components/ResetPasswordForm';
import { useSession } from '../../../core/hooks/session.hook';
import { INewResetPasswordCredentials } from '../models/new.password.model';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../navigation/enums/routes.enum';
import { useParams } from 'react-router-dom';

export const ResetPasswordPage: FC = () => {
    const { resetPasswordHash } = useSession();
    const { id } = useParams<string>();
    const navigate = useNavigate();

    if (!id) {
        return null;
    }

    const onSubmitClick = async (credentials: INewResetPasswordCredentials) => {
        const { password } = credentials;
        await resetPasswordHash(id, password).then(() =>
            navigate({ pathname: ROUTES.SUCCESS_PASSWORD })
        );
    };

    return <ResetPasswordForm onSubmitClick={onSubmitClick} />;
};
