import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { useSession } from 'core/hooks/session.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';

import { LoginForm } from '../components/loginForm/LoginForm';
// import { ILocationState } from '../components/privateRoute/PrivateRoute';
import { ILoginCredentials } from '../models/login.model';

export const LoginPage: FC = () => {
    const { isLoggedIn, login, logout, error } = useSession();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [open, setOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (isLoggedIn) {
            logout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error) {
            setIsLoading(false);
        }
    }, [error]);

    //NOTE: if user was on some page or link before arriving on login page, redirect
    // will "throw" him on that page/link after he signs in
    const onSubmitClick = async (credentials: ILoginCredentials) => {
        setIsLoading(true);
        if (await login(credentials)) {
            navigate({
                pathname: state?.from.pathname
                    ? state?.from.pathname
                    : ROUTES.PROJECTS,
                search: state?.from.search ? state?.from.search : '',
            });
        } else {
            handleClick();
        }
    };

    return (
        <>
            <LoginForm onSubmitClick={onSubmitClick} isLoading={isLoading} />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Wrong credentials"
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                <div>
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleClose}
                        severity="error"
                    >
                        {typeof error === 'object' && error?.main}
                    </MuiAlert>
                </div>
            </Snackbar>
        </>
    );
};
