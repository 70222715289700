/** @jsxImportSource @emotion/react */
import {
    Alert,
    AlertTitle,
    Box,
    CircularProgress,
    CssBaseline,
    GlobalStyles,
    Snackbar,
    SnackbarCloseReason,
} from '@mui/material';
import { useSession } from 'core/hooks/session.hook';
import { ActivityDialogProvider } from 'modules/activity/components/ActivityDialogProvider';
import { UserFormProvider } from 'modules/users/components/UserFormProvider';
import {
    Suspense,
    SyntheticEvent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { navigationItems } from '../../modules/navigation/constants/navigation-items';
import { darkTheme, lightTheme } from '../constants/themes';
import { ActionStatuses } from './ActionStatusSnackbar';
import { GeneralDialogContainer } from './shared/GeneralDialog/GeneralDialogContainer';
import { PulseAppBar } from './shared/topBars/AppBar';
import { ThemeSetterContext } from './ThemeSetterProvider';
import { UserAlertBox } from './UserAlertBox';
import { checkNetwork } from 'core/helpers/checkNetwork';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

const privateContentRoutes = navigationItems.filter(
    ({ contentComponent }) => contentComponent
);

// const isExact = (path: string) => {
//     switch (path) {
//         case ROUTES.USER:
//             return false;
//         default:
//             return true;
//     }
// };

export const Layout = () => {
    const [theme] = useContext(ThemeSetterContext);
    const { abilities, error, me } = useSession();
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const [network, setNetwork] = useState<
        'offline' | 'slow' | 'good' | 'average' | 'unknown' | null
    >(null);

    const handleClose = (
        event: SyntheticEvent | Event,
        reason?: SnackbarCloseReason
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const checkNetworkQuality = () => {
        const check = checkNetwork();
        setNetwork(check);

        if (check === 'offline') {
            setOpen(true);
        } else {
            setOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('online', checkNetworkQuality);
        window.addEventListener('offline', checkNetworkQuality);

        checkNetworkQuality();

        return () => {
            window.removeEventListener('online', checkNetworkQuality);
            window.removeEventListener('offline', checkNetworkQuality);
        };
    }, []);

    useEffect(() => {
        if (
            error &&
            typeof error === 'string' &&
            error.includes('Network error')
        ) {
            const check = checkNetwork();
            setNetwork(check);
            if (check === 'offline' || check === 'slow') {
                setOpen(true);
            }
        }
    }, [error]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
                <>
                    <CssBaseline />
                    <GlobalStyles
                        styles={{
                            body: {
                                background: 'white',
                                color: '#363537',
                                fontFamily:
                                    'Tahoma, Helvetica, Arial, Roboto, sans-serif',
                                transition: 'all 0.50s linear',
                            },
                        }}
                    />

                    <Box sx={{ width: '100%' }}>
                        <ActivityDialogProvider>
                            <UserFormProvider>
                                <PulseAppBar />
                            </UserFormProvider>
                        </ActivityDialogProvider>

                        {error && !me && (
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                }}
                            >
                                <UserAlertBox
                                    severity="info"
                                    title="Session error"
                                    text="Your session has encountered an error. Please log out and log back in to continue."
                                />
                            </Box>
                        )}

                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            autoHideDuration={6000}
                            open={open}
                            onClose={handleClose}
                            key={'neetw'}
                        >
                            <Alert onClose={handleClose} severity="warning">
                                <AlertTitle>Network</AlertTitle>
                                {network === 'offline' &&
                                    'It seems like you’ve lost your internet connection. Please check your connection and try again.'}
                                {network === 'slow' &&
                                    'Your internet connection is currently slow. This may affect your experience.'}
                            </Alert>
                        </Snackbar>

                        {!me && !error && (
                            <Box sx={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </Box>
                        )}
                        <Suspense
                            fallback={
                                <Box sx={{ textAlign: 'center' }} mt={10}>
                                    <CircularProgress
                                        size={50}
                                        color="secondary"
                                    />
                                </Box>
                            }
                        >
                            <Routes>
                                {privateContentRoutes
                                    .filter(({ path }) =>
                                        abilities.can('navigateTo', path)
                                    )
                                    .map(
                                        ({ path, contentComponent }, index) => {
                                            const Component =
                                                contentComponent as React.ComponentType<any>;

                                            return (
                                                <Route
                                                    key={index}
                                                    path={path}
                                                    element={<Component />}
                                                />
                                            );
                                        }
                                    )}
                            </Routes>
                        </Suspense>
                        {location.pathname === '/' && (
                            <Navigate to="/projects" replace={true} />
                        )}
                    </Box>
                    <GeneralDialogContainer />
                    <ActionStatuses />
                </>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
