/** @jsxImportSource @emotion/react */
import { Dialog, DialogActions, DialogTitle, Theme, css } from '@mui/material';
import { FC, ReactNode } from 'react';
import { LoseButtonFocus } from 'core/helpers/loseButtonFocus';
import DialogContent from '@mui/material/DialogContent';

interface DialogWrapperProps {
    isOpen: boolean;
    title?: string;
    handleClose?: () => void;
    alertModal?: boolean;
    setShouldRefetchParticipants?: any;
    children: ReactNode;
    actions?: ReactNode;
}

const useStyles = {
    root: (theme: Theme) => css`
        width: 90%;
        height: 90%;
        max-width: 1360px;
        max-height: 1000px;
        margin: auto;
        background-color: ${theme.palette.background.default};
        border-radius: ${theme.spacing(0.5)};
    `,
    modalContent: css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        position: relative;
        padding: 0;
    `,
    titleCotainer: (theme: Theme) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: ${theme.spacing(4)};
        padding-bottom: ${theme.spacing(4)};
        font-size: 180%;
    `,
    alert: (theme: Theme) => css`
        max-width: 600px;
        width: 100%;
        height: fit-content;
        margin: auto;
        background-color: ${theme.palette.background.default};
        border-radius: ${theme.spacing(0.5)};
        & .MuiDialogContent-root:first-of-type {
            margin: 0;
            padding: 0;
            padding-top: ${theme.spacing(3)};
            padding-bottom: ${theme.spacing(3)};
        }
    `,
};

export const FormDialogWrapper: FC<DialogWrapperProps> = ({
    children,
    title,
    isOpen,
    handleClose,
    alertModal = false,
    setShouldRefetchParticipants,
    actions,
}) => {
    return (
        <Dialog
            PaperProps={{
                style: {
                    position: 'relative',
                },
            }}
            fullScreen
            open={isOpen}
            onClose={() => {
                handleClose && handleClose();
            }}
            css={
                alertModal !== false
                    ? useStyles.alert
                    : // ? useStyles.alert + ' alertModal'
                      useStyles.root
            }
            TransitionProps={{
                onExited: () => {
                    LoseButtonFocus();
                    if (setShouldRefetchParticipants) {
                        setShouldRefetchParticipants(
                            (shouldRefetchParticipants: any) =>
                                !shouldRefetchParticipants
                        );
                    }
                },
            }}
        >
            {title && (
                <DialogTitle css={useStyles.titleCotainer}>{title}</DialogTitle>
            )}
            <DialogContent css={useStyles.modalContent}>
                {children}
            </DialogContent>
            {/* <DialogContent>
                <div css={useStyles.modalContent}>{children}</div>
            </DialogContent> */}
            {actions && <DialogActions>{actions}</DialogActions>}
        </Dialog>
    );
};
