import { defaultLanguage } from 'core/constants/supported-language';
import { LocalStorageKeys } from 'core/enums/local-storage-keys.enum';
import i18next from 'i18next';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const storedLangValue = localStorage.getItem(LocalStorageKeys.appLang);

interface MultiLanguageSupportProps {
    language: string;
    setLanguage: (lang: string) => void;
    //TODO: fix TS here
    // t: (key: keyof ValidTranslationKeys, ...args: any) => string;
    t: any;
}

const MultiLanguageSupportContext = createContext<MultiLanguageSupportProps>({
    language: storedLangValue || defaultLanguage,
    setLanguage: () => {},
    t: () => '',
});

const MultiLanguageSupportProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [language, setLanguage] = useState(
        storedLangValue || defaultLanguage
    );

    const { t } = useTranslation();

    useEffect(() => {
        setLanguage(language);
        i18next.changeLanguage(language);
        localStorage.setItem(LocalStorageKeys.appLang, language);
    }, [language]);

    return (
        <MultiLanguageSupportContext.Provider
            value={{ language, setLanguage, t }}
        >
            {children}
        </MultiLanguageSupportContext.Provider>
    );
};

export { MultiLanguageSupportProvider, MultiLanguageSupportContext };
