/** @jsxImportSource @emotion/react */
import {
    Box,
    Container,
    css,
    Grid,
    List,
    ListItem,
    Theme,
    Typography,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import {
    IObjectToPostProps,
    ISurveyPayload,
    ISurveyPayloadFactorAnswers,
} from 'core/interfaces/survey-answering.interface';
import { FactorIcon } from 'modules/success-factors/components/FactorIcon';
import { FactorValue } from 'modules/success-factors/interfaces/FactorValue';
import { IFactorDetails } from 'modules/success-factors/models/success-factor.model';
import { FC, useCallback, useContext, useMemo } from 'react';
import { filterTranslatesBuilder } from './filterTranslatesBuilderHelper';
import StarIcon from '@mui/icons-material/Star';
const useStyles = {
    root: (theme: Theme) => css`
        background-color: ${theme.palette.background.paper};
        & .MuiRating-root.Mui-disabled {
            opacity: 1;
        }
    `,
    overViewContainer: (theme: Theme) => css`
        max-width: 864px;
        margin-bottom: ${theme.spacing(15)};
        border-radius: ${theme.spacing(0.5)};
        border: 1px solid ${theme.palette.divider};
        background-color: ${theme.palette.common.white};
    `,
    stepIntro: (theme: Theme) => css`
        padding: 48px 0;
        ${theme.breakpoints.down('sm')} {
            padding: 24px 0;
        }
    `,
    stepHeading: (theme: Theme) => css`
        ${theme.breakpoints.down('sm')} {
            font-size: 20px;
            line-height: 24px;
        }
    `,
    ratingBox: (theme: Theme) => css`
        width: 450px;
        padding: ${theme.spacing(1)};
        ${theme.breakpoints.down('sm')} {
            width: 100%;
        }
    `,
    factorsContainer: (theme: Theme) => css`
        margin-bottom: ${theme.spacing(4)};
    `,
    factorBox: (theme: Theme) => css`
        border-radius: ${theme.spacing(0.5)};
        border: 1px solid ${theme.palette.divider};
    `,
    factorHeading: (factorType: string) => (theme: Theme) => css`
        padding: ${theme.spacing(2)};

        ${factorType === 'f-great' &&
        `
            background-color: #e8fdf2;
            color: #006b47;
            `}
        ${factorType === 'f-improve' &&
        `
            background-color: #ffebe6;
            color: #b22400;
            `}
        ${factorType === 'f-neutral' &&
        `
            background-color: #f4f5f7;
            `}
    `,
    factorListItem: (theme: Theme) => css`
        padding: ${theme.spacing(1.5)};
        & svg {
            margin-right: ${theme.spacing(2)};
        }
    `,
    feedbackBox: (theme: Theme) => css`
        border-radius: ${theme.spacing(0.5)};
        background-color: #f4f5f7;
        padding: ${theme.spacing(2)};
    `,
    ratingScore: css`
        margin-left: 15px;
        svg {
            width: 32px;
            height: 32px;
        }
    `,
};

interface ISurveyOverviewFactorsProps
    extends Partial<Pick<ISurveyPayload, 'factor_answers'>> {
    value: FactorValue;
    label: string;
    surveyFactorsMap: Map<string, IFactorDetails>;
}

const SurveyOverviewFactors: FC<ISurveyOverviewFactorsProps> = ({
    factor_answers,
    value,
    label,
    surveyFactorsMap,
}) => {
    const factorType = useMemo(
        () => (value === 'great' ? 'f-great' : 'f-improve'),
        [value]
    );

    const factorAnswersMapper = useCallback(
        ({ factor: { id } }: ISurveyPayloadFactorAnswers) => {
            const factor = surveyFactorsMap.get(id.toString());

            return !factor ? (
                <></>
            ) : (
                <ListItem key={id} css={useStyles.factorListItem}>
                    <FactorIcon {...factor} />
                    <Typography variant="body2">{factor.title}</Typography>
                </ListItem>
            );
        },
        [surveyFactorsMap]
    );

    return (
        <Grid item xs={12} sm={6}>
            <Box css={useStyles.factorBox}>
                <Box css={useStyles.factorHeading(factorType)}>
                    <Typography variant="overline">{label}</Typography>
                </Box>
                <List>
                    {factor_answers
                        ?.filter((item) => item.value?.toLowerCase() === value)
                        .map(factorAnswersMapper)}
                </List>
            </Box>
        </Grid>
    );
};

export const SurveyOverview: FC<IObjectToPostProps> = ({
    objectToPost,
    surveyQuestions,
    surveyFactors,
    surveyLabelTranslations,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);

    const filterTranslates = filterTranslatesBuilder(surveyLabelTranslations);

    const surveyFactorsMap = useMemo(
        () =>
            surveyFactors.reduce(
                (map, { id, ...details }) => map.set(id, details),
                new Map<string, IFactorDetails>()
            ),
        [surveyFactors]
    );

    return (
        <Box css={useStyles.root}>
            <Container>
                <Box css={useStyles.stepIntro}>
                    <Typography
                        variant="h2"
                        align="center"
                        css={useStyles.stepHeading}
                    >
                        {filterTranslates(
                            'overviewTitle',
                            t('your-answers-have-been-registered')
                        )}
                    </Typography>
                </Box>

                <Container css={useStyles.overViewContainer}>
                    <Box py={3} display="flex" justifyContent="center">
                        <Typography variant="overline" color="textSecondary">
                            {filterTranslates(
                                'overviewSubtitle',
                                t('your-answers-summary')
                            )}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        mb={4}
                    >
                        {objectToPost?.question_answers.map((item, idx) => (
                            <Box
                                css={useStyles.ratingBox}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                key={idx}
                            >
                                <Typography variant="body2">
                                    {surveyQuestions
                                        .filter(
                                            ({ id }) =>
                                                id === item.scale_question.id
                                        )
                                        .map(({ translated_name }, idxx) => (
                                            <span key={idxx}>
                                                {translated_name}
                                            </span>
                                        ))}
                                </Typography>
                                <Rating
                                    css={useStyles.ratingScore}
                                    key={idx}
                                    value={item.value / 2}
                                    disabled
                                    precision={0.5}
                                    emptyIcon={
                                        <StarIcon
                                            style={{ opacity: 0.5 }}
                                            fontSize="inherit"
                                        />
                                    }
                                />
                            </Box>
                        ))}
                    </Box>
                    {objectToPost && objectToPost.factor_answers.length > 0 ? (
                        <Grid
                            container
                            spacing={2}
                            css={useStyles.factorsContainer}
                        >
                            <SurveyOverviewFactors
                                {...objectToPost}
                                label={filterTranslates(
                                    'overviewGreat',
                                    t('overview-great')
                                )}
                                value="great"
                                surveyFactorsMap={surveyFactorsMap}
                            />

                            <SurveyOverviewFactors
                                {...objectToPost}
                                label={filterTranslates(
                                    'overviewImprove',
                                    t('overview-improve')
                                )}
                                value="improvable"
                                surveyFactorsMap={surveyFactorsMap}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}

                    <Box css={useStyles.feedbackBox} mb={3}>
                        <Typography variant="overline" color="textSecondary">
                            {filterTranslates(
                                'overviewSuggestions',
                                t('your-feedback-and-suggestions')
                            )}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {objectToPost?.suggestion.text}
                        </Typography>
                    </Box>
                </Container>
            </Container>
        </Box>
    );
};
