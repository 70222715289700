import { tomorrow } from 'core/helpers/tomorrow';
import { IEntity } from '../../../core/interfaces/entity.interface';
import { FrequencyValue } from '../enums/frequency-value';

export interface IPulseScheduleDetails {
    frequency: FrequencyValue;
    startDate: string;
    scheduleId?: string;
}

export interface IPulseSchedule extends IEntity, IPulseScheduleDetails { }

export interface IPulseScheduleResponseDetails {
    frequency: FrequencyValue;
    not_before: string;
}

export interface IPulseScheduleResponse
    extends IEntity<number>,
    IPulseScheduleResponseDetails {
    pulse: IEntity<number>;
}

export const getEmptyPulseSchedule = (): IPulseScheduleDetails => ({
    frequency: FrequencyValue.NONE,
    startDate: tomorrow().toISOString().split('T')[0],
});

export const createPulseSchedule = ({
    id,
    scheduleId,
    ...props
}: Partial<IPulseSchedule>): IPulseSchedule => {
    if (!id) {
        throw new Error('Unable to create a user without ID!');
    }

    return {
        id,
        ...getEmptyPulseSchedule(),
        ...props,
        scheduleId: scheduleId ?? id.toString(),
    };
};

export const createPulseScheduleFromResponse = (
    response: IPulseScheduleResponse | null
): IPulseSchedule => {
    if (!response) {
        throw new Error();
    }

    const { id, not_before, ...props } = response;

    return createPulseSchedule({
        id: id.toString(),
        startDate: not_before.split('T')[0],
        ...props,
    });
};

export const createPulseScheduleCommand = ({
    startDate,
    ...props
}: IPulseScheduleDetails): IPulseScheduleResponseDetails => {
    if (!startDate) {
        throw new Error();
    }

    return {
        not_before: startDate,
        ...props,
    };
};
