/* Function to scroll to top of the page. Div with ID root was selected because all of the content is inside that element. 
Also in CSS the div with ID root is treated as body element, because all content is inside that div element. */

const rootElement = document.getElementById('root');

const scrollAppToTop = () => {
    if (rootElement && rootElement?.scrollTop) {
        rootElement.scrollTop = 0;
    }
};

export { rootElement, scrollAppToTop };
