import { useObservable } from '@mindspace-io/react';
import { useContext, useEffect, useState } from 'react';
import { Observable } from 'rxjs';
import { EntitiesContext } from '../constants/entities.context';
import { IEntity } from '../interfaces/entity.interface';

export interface IUseCurrentPageEntities<T> {
    total: number | null;
    fetching: boolean | undefined;
    currentPageEntities: T[] | undefined;
    allPagesEntities: T[] | undefined;
    store: any;
    sortedPageEntities: T[];
    editedPageEntities: T[] | null;
    setEditedPageEntities: (entities: T[] | null) => void;
}

export const useCurrentPageEntities = <T extends IEntity>(
    compareFn?: (a: T, b: T) => number
): IUseCurrentPageEntities<T> => {
    const { query, store } = useContext(EntitiesContext);

    const { loading, total } = query.getValue();

    const { entities, currentPageIds, pagesIds } = query.getValue();

    const [currentPageEntities] = useObservable(
        query.currentPageEntities$ as Observable<T[]>,
        currentPageIds
            .map((id) => entities?.[id] ?? null)
            .filter((x) => x) as T[]
    );

    const [editedPageEntities, setEditedPageEntities] = useState<T[] | null>(
        null
    );

    useEffect(() => {
        setEditedPageEntities(null);
    }, [currentPageEntities]);

    const sortedPageEntities = (
        editedPageEntities ??
        (currentPageEntities?.map((entity, index) => ({
            ...entity,
            order: index,
        })) ?? [])
    ).sort(compareFn);

    const [allPagesEntities] = useObservable(
        query.allPageEntities$ as Observable<T[]>,
        Array.from(new Set(([] as string[]).concat(...pagesIds)))
            .map((id) => entities?.[id] ?? null)
            .filter((x) => x) as T[]
    );

    const [fetching] = useObservable(query.selectLoading(), loading ?? false);

    return {
        total,
        fetching,
        allPagesEntities,
        store,
        currentPageEntities,
        sortedPageEntities,
        editedPageEntities,
        setEditedPageEntities,
    };
};
