import { apiService } from 'core/services/apiService';
import {
    createContext,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { deepFlat } from '../../../core/helpers/deepFlat';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { ICategory } from '../models/category.model';
import {
    createDimensionFromResponse,
    IDimension,
    IDimensionResponse,
} from '../models/dimension.model';
import { categoriesQuery } from '../state/categories.query';
import { categoriesStore } from '../state/categories.store';

// const getCategoryByIdFromDimensions =
//     (dimensions: IDimension[]) => (id: string) => {
//         for (const dimension of dimensions) {
//             for (const category of deepFlat(dimension.categories)) {
//                 if (category.id === id) return category;
//             }
//         }

//         return null;
//     };

interface IDimensionsContext {
    dimensions: IDimension[];
    isFetching: boolean;
    fetchDimensions: () => Promise<IDimension[] | null>;
    getCategoryById: (id: string) => ICategory | null;
}

export const DimensionsContext = createContext<IDimensionsContext>({
    dimensions: [],
    isFetching: false,
    fetchDimensions: async () => {
        // console.log('Use provider!');
        return [];
    },
    getCategoryById: () => {
        // console.log('Use provider!');
        return null;
    },
});

export const DimensionsProvider = WithProvider(
    ({ children }: { children: ReactNode }) => {
        const [isFetching, setIsFetching] = useState(false);
        const [dimensions, setDimensions] = useState<IDimension[]>([]);

        const getCategoryById = useCallback(
            (id: string) => {
                for (const dimension of dimensions) {
                    for (const category of deepFlat(dimension.categories)) {
                        if (category.id === id) return category;
                    }
                }
                return null;
            },
            [dimensions]
        );

        useEffect(() => {
            fetchDimensions();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const fetchDimensions = async (force = false) => {
            if (isFetching && !force) {
                return null;
            } else {
                setIsFetching(true);
            }

            try {
                const { data } = await apiService.get<{
                    [key: string]: IDimensionResponse;
                }>(`/dimension-entries`);

                const tempDimensions = Object.keys(data).map((key) =>
                    createDimensionFromResponse(data[key])
                );

                setDimensions(tempDimensions);
                setIsFetching(false);

                return tempDimensions;
            } catch (e: any) {
                return null;
            }
        };

        useEffect(() => {
            categoriesStore.add(
                dimensions.map(({ categories }) => deepFlat(categories)).flat()
            );
        }, [dimensions]);

        return (
            <DimensionsContext.Provider
                value={{
                    dimensions,
                    isFetching,
                    fetchDimensions,
                    getCategoryById,
                }}
            >
                {children}
            </DimensionsContext.Provider>
        );
    },
    categoriesQuery
);
