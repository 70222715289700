import { FC } from 'react';
import { EntitiesContext } from '../constants/entities.context';
import { IEntity } from '../interfaces/entity.interface';
import { EntitiesQuery } from '../state/entities.query';
import { EntitiesStore } from '../state/entities.store';

interface IEntityProvider<T extends IEntity, R> {
    query: EntitiesQuery<T>;
    store?: EntitiesStore<T, R>;
    children: React.ReactNode;
}

export const EntityProvider: FC<IEntityProvider<IEntity, IEntity>> = ({
    query,
    store,
    children,
}) => {
    if (!store) {
        store = query.store;
    }

    return (
        <EntitiesContext.Provider value={{ query, store }}>
            {children}
        </EntitiesContext.Provider>
    );
};
