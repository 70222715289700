/** @jsxImportSource @emotion/react */

import { Avatar, Box, Divider, Link, Theme, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, css } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { HelpOutline } from '@mui/icons-material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoreIcon from '@mui/icons-material/MoreVert';
import RestoreIcon from '@mui/icons-material/Restore';
import MobileMenu from 'core/components/shared/topBars/MobileMenu';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { NavChips } from 'core/components/shared/topBars/NavChips';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { ActivityDialogContext } from 'modules/activity/components/ActivityDialogProvider';
import { Can } from 'modules/login/components/Can';
import { UserForm } from 'modules/users/components/UserForm';
import { UserFormContext } from 'modules/users/components/UserFormProvider';
import { IUser } from 'modules/users/models/user.model';
import { usersQuery } from 'modules/users/state/users.query';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import LogoWithText from 'styles/material-ui/elements/logo-with-text';
import { ActivityPage } from '../../../../modules/activity/pages/ActivityPage';
import { HelpFeedbackModalContent } from '../../../../modules/help-feedback/components/HelpFeedbackModalContent';
import { useDialog } from '../../../../modules/projects/components/use-dialog.hook';
import { useSession } from '../../../hooks/session.hook';
import { MultiLanguageSupportContext } from '../../MultiLanguageSupportProvider';
import { FormDialogWrapper } from '../FormDialogWrapper';

const useStyles = {
    grow: css`
        flex-grow: 1;
    `,
    toolbar: (theme: Theme) => css`
        background: ${theme.palette.background.default};
        height: 64px;
    `,
    menuButton: (theme: Theme) => css`
        margin-right: ${theme.spacing(2)};
    `,
    title: (theme: Theme) => css`
        display: none;
        ${theme.breakpoints.up('sm')} {
            display: block;
        }
    `,
    search: (theme: Theme) => css`
        position: relative;
        border-radius: ${theme.shape.borderRadius};
        background-color: ${alpha(theme.palette.common.white, 0.15)};
        &:hover {
            background-color: ${alpha(theme.palette.common.white, 0.25)};
        }
        margin-right: ${theme.spacing(2)};
        margin-left: 0;
        width: 100%;
        ${theme.breakpoints.up('sm')} {
            margin-left: ${theme.spacing(3)};
            width: auto;
        }
    `,
    searchIcon: (theme: Theme) => css`
        padding: ${theme.spacing(0, 2)};
        height: 100%;
        position: absolute;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    inputRoot: css`
        color: inherit;
    `,
    inputInput: (theme: Theme) => css`
        padding: ${theme.spacing(1, 1, 1, 0)};
        /* // vertical padding + font size from searchIcon */
        padding-left: calc(1em + ${theme.spacing(4)});
        transition: ${theme.transitions.create('width')};
        width: 100%;
        ${theme.breakpoints.up('md')} {
            width: 20ch;
        }
    `,
    sectionDesktop: (theme: Theme) => css`
        display: none;
        ${theme.breakpoints.up('md')} {
            display: flex;
            align-items: center;
            & :not(last-child) > span {
                height: 20px;
                width: 20px;
            }
        }
    `,
    sectionMobile: (theme: Theme) => css`
        display: flex;
        ${theme.breakpoints.up('md')} {
            display: none;
        }
    `,
    profileMenuMobile: (theme: Theme) => css`
        text-align: right;
        & ul {
            width: 240px;
            padding: 0 ${theme.spacing(1)} ${theme.spacing(3)};
        }
        & li {
            justify-content: flex-end;
        }
    `,
    profileMenu: css`
        text-align: right;
        & ul {
            width: 260px;
        }
        & li {
            justify-content: flex-end;
        }
    `,
    profileMenuActions: css`
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #dfe1e6;
        padding-top: 24px;
    `,
    purple: css`
        color: white;
        background-color: #ff9880;
        text-transform: uppercase;
    `,
    navWrap: css`
        display: flex;
        justify-content: center;
        width: 100%;
    `,

    actions: css`
        background-color: #f4f5f7;
        display: flex;
        justify-content: flex-end;
        padding: 6px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    `,
    cursorPointer: css`
        cursor: pointer;
    `,
};

const PulseAppBarContainer = () => {
    const { logout, me } = useSession();
    const { t } = useContext(MultiLanguageSupportContext);
    const { isOpen, close, open } = useContext(UserFormContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const {
        open: isHelpFeedbackModalOpen,
        handleOpen,
        handleClose,
    } = useDialog();
    const { updateSingle } = useCrud<IUser>();

    const closeHelp = useCallback(async () => {
        handleClose();
        me && (await updateSingle({ ...me, isNew: false }));
    }, [me, updateSingle, handleClose]);

    useEffect(() => {
        if (me && me.isNew) handleOpen();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userData = useMemo(
        () => ({
            avatarID: me?.name.substring(0, 1) ?? '',
            name: me?.name ?? '',
        }),
        [me]
    );

    const editUser = useCallback(() => {
        setAnchorEl(null);
        setMobileMoreAnchorEl(null);
        me && open(me);
    }, [me, open]);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = async () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const { isOpened, closeModal, openActivity } = useContext(
        ActivityDialogContext
    );

    const handleMobileMenuOpen = (event: any) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            css={useStyles.profileMenu}
            MenuListProps={{
                sx: {
                    width: 259,
                    '& .MuiMenuItem-root': {
                        whiteSpace: 'normal',
                    },
                },
            }}
        >
            {userData && [
                <MenuItem key="name">
                    <Typography variant="subtitle2">{userData.name}</Typography>
                </MenuItem>,

                <Divider key="divider" />,

                <MenuItem onClick={editUser} key="edit">
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('edit-user')}
                    </Typography>
                    <IconButton
                        aria-label="edit user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="default"
                        size="large"
                    >
                        <ManageAccountsIcon />
                    </IconButton>
                </MenuItem>,
            ]}
            <MenuItem onClick={() => logout()}>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('logout')}
                </Typography>
                <IconButton
                    aria-label="logout"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="default"
                    size="large"
                >
                    <ExitToAppIcon style={{ color: '#6B778C' }} />
                </IconButton>
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            css={useStyles.profileMenuMobile}
            MenuListProps={{
                sx: {
                    width: 240,
                    '& .MuiMenuItem-root': {
                        whiteSpace: 'normal',
                    },
                },
            }}
        >
            {userData && [
                <MenuItem key="name">
                    <Typography variant="subtitle2">{userData.name}</Typography>
                </MenuItem>,
                <Divider key="divider" />,
                <MenuItem onClick={editUser} key="edit">
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('edit-user')}
                    </Typography>
                    <IconButton
                        aria-label="edit user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="default"
                        size="large"
                    >
                        <ManageAccountsIcon />
                    </IconButton>
                </MenuItem>,
                <Can I="viewActivityOf" a="IUser" key="activity">
                    <MenuItem>
                        <Typography variant="subtitle2" color="textSecondary">
                            Activity
                        </Typography>
                        <IconButton
                            aria-label="activity of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={() => openActivity()}
                            color="default"
                            size="large"
                        >
                            <RestoreIcon />
                        </IconButton>
                    </MenuItem>
                </Can>,
            ]}

            <MenuItem>
                <Typography variant="subtitle2" color="textSecondary">
                    Help
                </Typography>
                <IconButton
                    aria-label="help"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleOpen}
                    color="default"
                    size="large"
                >
                    <HelpOutline />
                </IconButton>
            </MenuItem>
            <MenuItem onClick={() => logout()}>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('logout')}
                </Typography>
                <IconButton
                    aria-label="logout"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="default"
                    size="large"
                >
                    <ExitToAppIcon style={{ color: '#6B778C' }} />
                </IconButton>
            </MenuItem>
        </Menu>
    );

    return (
        <div css={useStyles.grow}>
            <AppBar position="static" elevation={0}>
                <Toolbar css={useStyles.toolbar}>
                    <Box
                        sx={{
                            display: {
                                md: 'none',
                                lg: 'none',
                                sm: 'block',
                                xs: 'block',
                            },
                        }}
                    >
                        <MobileMenu />
                    </Box>

                    <Link>
                        <LogoWithText />
                    </Link>

                    <div css={useStyles.navWrap}>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <NavChips />
                        </Box>
                    </div>

                    <div css={useStyles.grow} />
                    <div css={useStyles.sectionDesktop}>
                        <Can I="viewActivityOf" a="IUser">
                            <IconButton
                                aria-label="activity of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={() => openActivity()}
                                color="default"
                                size="large"
                            >
                                <RestoreIcon />
                            </IconButton>
                        </Can>
                        <IconButton
                            aria-label="help"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleOpen}
                            color="default"
                            size="large"
                        >
                            <HelpOutline />
                        </IconButton>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="primary"
                            size="large"
                        >
                            <Avatar css={useStyles.purple}>
                                {userData.avatarID}
                            </Avatar>
                        </IconButton>
                    </div>
                    <div css={useStyles.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="default"
                            size="large"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <FormDialogWrapper isOpen={isOpen} handleClose={close}>
                <UserForm />
            </FormDialogWrapper>
            <FormDialogWrapper
                isOpen={isHelpFeedbackModalOpen}
                handleClose={closeHelp}
            >
                <HelpFeedbackModalContent closeHelp={closeHelp} />
            </FormDialogWrapper>
            <FormDialogWrapper isOpen={isOpened} handleClose={closeModal}>
                <ActivityPage />
            </FormDialogWrapper>
        </div>
    );
};

export const PulseAppBar = WithProvider(PulseAppBarContainer, usersQuery);
