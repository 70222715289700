/** @jsxImportSource @emotion/react */

import Box from '@mui/material/Box';

import { useSession } from 'core/hooks/session.hook';
import { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../../modules/navigation/enums/routes.enum';
import { Theme } from '@mui/material';
import { css } from '@emotion/react';
const styles = {
    root: css`
        width: fit-content;
    `,
    list: (theme: Theme) => css`
        display: flex;
        justify-content: center;
        list-style: none;
        background-color: #f4f5f7;
        padding: ${theme.spacing(0.5)};
        border-radius: 96px;
    `,
    chip: (theme: Theme) => css`
        cursor: pointer;
        background-color: transparent;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        text-decoration: none;
        color: ${theme.palette.text.primary};
        display: block;
        border-radius: 64px;
        padding: ${theme.spacing(2)} ${theme.spacing(3)};

        ${theme.breakpoints.down('lg')} {
            padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
        }
        &:hover {
            color: ${theme.palette.primary.light};
            background-color: transparent;
        }
        &.active {
            color: ${theme.palette.common.white};
            background-color: #172b4d;
        }
        &.active:hover {
            background-color: ${theme.palette.primary.light};
            cursor: default;
        }
    `,
};

const data = [
    { label: 'Projects', route: ROUTES.PROJECTS },
    { label: 'Analytics', route: ROUTES.ANALYTICS },
    { label: 'Account Settings', route: ROUTES.ACCOUNT_SETTINGS },
    { label: 'Super Admin', route: ROUTES.SUPER_ADMIN },
];

export const NavChips: FC = () => {
    const { abilities } = useSession();

    const chipData = useMemo(
        () => data.filter(({ route }) => abilities.can('navigateTo', route)),
        [abilities]
    );

    return (
        <Box css={styles.root}>
            <Box component="ul" css={styles.list}>
                {chipData.map(({ label, route }) => {
                    return (
                        <li key={route}>
                            <NavLink
                                to={route}
                                css={styles.chip}
                                color="primary"
                                className={(isActive) =>
                                    isActive ? 'active' : ''
                                }
                            >
                                {label}
                            </NavLink>
                        </li>
                    );
                })}
            </Box>
        </Box>
    );
};
