import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import { useSession } from 'core/hooks/session.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { AcitavteAccountForm } from '../components/accountForm/AccountForm';
import { INewResetPasswordCredentials } from '../../resetpassword/models/new.password.model';
import { useParams } from 'react-router-dom';

export const SetupAccount: FC = () => {
    const { error } = useSession();
    const navigate = useNavigate();
    const { id } = useParams<string>();
    const [open, setOpen] = useState(false);
    const { resetPasswordHash } = useSession();

    if (!id) {
        return null;
    }

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmitClick = async (credentials: INewResetPasswordCredentials) => {
        const { password } = credentials;
        await resetPasswordHash(id, password).then(() =>
            navigate({ pathname: ROUTES.SUCCESS_PASSWORD })
        );
    };

    return (
        <>
            <AcitavteAccountForm onSubmitClick={onSubmitClick} />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Wrong credentials"
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity="error"
                >
                    {typeof error === 'object' && error?.main}
                </MuiAlert>
            </Snackbar>
        </>
    );
};
