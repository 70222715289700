import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { EntitiesContext } from 'core/constants/entities.context';
import { WithProvider } from 'core/hocs/WithProvider';
import { useCrud } from 'core/hooks/crud.hook';
import { useCurrentPage } from 'core/hooks/current-page.hook';
import { IUser } from 'modules/users/models/user.model';
import { createContext, useContext, useState } from 'react';
import { activityQuery } from '../state/activity.query';

interface IActivityDialogContext {
    isOpened: boolean;
    closeModal: () => void;
    openActivity: (user?: IUser) => void;
    subtitle: string;
}

export const ActivityDialogContext = createContext<IActivityDialogContext>(
    {} as IActivityDialogContext
);

export const ActivityDialogProvider = WithProvider(
    ({ children }: { children: React.ReactNode | React.ReactElement }) => {
        const [isOpened, setIsOpened] = useState(false);

        const [subtitle, setSubtitle] = useState<string>('');

        const closeModal = () => {
            setIsOpened(false);
        };
        const { store } = useContext(EntitiesContext);
        const { setListSlug, fetch } = useCrud();
        const { t } = useContext(MultiLanguageSupportContext);
        const { clearCurrentPageEntities } = useCurrentPage();

        const openActivity = (user?: IUser) => {
            setSubtitle(user?.name ?? t('all'));

            const slug = `/activity${user ? '/' + user.id : ''}`;
            if (store.listSlug !== slug) {
                clearCurrentPageEntities();
                setTimeout(fetch);
            }
            setListSlug({ slug });
            setIsOpened(true);
        };

        return (
            <ActivityDialogContext.Provider
                value={{
                    isOpened,
                    closeModal,
                    openActivity,
                    subtitle,
                }}
            >
                {children}
            </ActivityDialogContext.Provider>
        );
    },
    activityQuery
);
