import { primaryOrderDirection } from 'core/constants/default-order-properties';
import { useContext } from 'react';
import { EntitiesContext } from '../constants/entities.context';
import { IEntity } from '../interfaces/entity.interface';
import { EntitiesStore, OrderDirection } from '../state/entities.store';

const setSearchTerm = <T extends IEntity, R>(store: EntitiesStore<T, R>) => (
    searchTerm: string
) => {
    const {
        ui: { searchTerm: lastSearchTerm },
    } = store.getValue();

    if (lastSearchTerm === searchTerm) {
        return;
    }

    store.patchUIState({
        searchTerm,
        pageNumber: 1,
    });
};

const setPageSize = <T extends IEntity, R>(store: EntitiesStore<T, R>) => (
    pageSize: number
) => {
    const {
        ui: { pageSize: lastPageSize },
    } = store.getValue();

    if (lastPageSize === pageSize) {
        return;
    }

    store.patchUIState({
        pageSize,
        pageNumber: 1,
    });
};

const setPageNumber = <T extends IEntity, R>(store: EntitiesStore<T, R>) => (
    pageNumber: number,
    force = false
) => {
    if (force) {
        const { ui } = store.getValue();

        store.fetchEntities({ ...ui, pageNumber });
    }

    store.patchUIState({
        pageNumber,
    });
};

const setOrderBy = <T extends IEntity, R>(store: EntitiesStore<T, R>) => (
    orderBy: string,
    orderDirection: OrderDirection = primaryOrderDirection
) => {
    store.patchUIState({
        orderBy,
        orderDirection,
    });
};

const setOrderDirection = <T extends IEntity, R>(
    store: EntitiesStore<T, R>
) => (orderDirection: OrderDirection) => {
    store.patchUIState({
        orderDirection,
    });
};

const clearCurrentPageEntities = <T extends IEntity, R>(
    store: EntitiesStore<T, R>
) => () => {
    store.setCurrentPageEntities([]);
};

export interface IUseCurrentPage {
    setSearchTerm: (searchTerm: string) => void;
    setPageSize: (pageSize: number) => void;
    incrementPageNumber: (increment?: number) => void;
    setPageNumber: (pageNumber: number, force?: boolean) => void;
    setOrderBy: (orderBy: string, orderDirection?: OrderDirection) => void;
    setOrderDirection: (orderDirection: OrderDirection) => void;
    clearCurrentPageEntities: () => void;
    clearTotal: () => void;
}

export const useCurrentPage = (): IUseCurrentPage => {
    const { store } = useContext(EntitiesContext);

    return {
        setSearchTerm: setSearchTerm(store),

        setPageSize: setPageSize(store),

        clearTotal: () => {
            store.update({ total: null });
        },

        incrementPageNumber: (increment = 1) => {
            const {
                ui: { pageNumber, pageSize },
                total,
            } = store.getValue();

            if (!total) {
                setPageNumber(store)(1, true);
            } else if (total && total > pageNumber * pageSize) {
                setPageNumber(store)(pageNumber + increment);
            }
        },

        setPageNumber: setPageNumber(store),

        setOrderBy: setOrderBy(store),

        setOrderDirection: setOrderDirection(store),

        clearCurrentPageEntities: clearCurrentPageEntities(store),
    };
};
