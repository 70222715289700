import * as Sentry from '@sentry/react';

import { ToBeDefined } from 'core/pages/ToBeDefined';
import { FeedbackLoopPage } from 'modules/feedback-loop/pages/FeedbackLoopPage';
import { PrivateRoute } from 'modules/login/components/privateRoute/PrivateRoute';
import { LoginPage } from 'modules/login/pages/LoginPage';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { SurveyPage } from 'modules/pulse-answering/pages/SurveyPage';
import { ResetPasswordPage } from 'modules/resetpassword/pages/ResetPasswordPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { Layout } from './core/components/Layout';
import { DataProtectionPolicy } from './modules/help-feedback/pages/DataProtectionPolicy';
import { SetupAccount } from './modules/login/pages/SetupAccount';
import { RequestResetPasswordPage } from './modules/resetpassword/pages/RequestPassowrdPage';
import { SuccessRequestPasswordPage } from './modules/resetpassword/pages/SuccesRequestPasswordPage';
import { SuccessNewPasswordPage } from './modules/resetpassword/pages/SuccessNewPasswordPage';

Sentry.init({
    dsn: 'https://a2b412e943d4498e915253cf978c24c4@sentry.q-tests.com/30',
    integrations: [Sentry.browserTracingIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const AppContainer = () => (
    <BrowserRouter>
        <Routes>
            <Route path={ROUTES.LOGIN} element={<LoginPage />} />
            <Route
                path={ROUTES.RESET_PASSWORD}
                element={<RequestResetPasswordPage />}
            />
            <Route
                path={ROUTES.SUCCESS_PASSWORD}
                element={<SuccessNewPasswordPage />}
            />
            <Route
                path={ROUTES.SUCCESS_REQUEST_PASSWORD}
                element={<SuccessRequestPasswordPage />}
            />
            <Route
                path={ROUTES.RESET_PASSWORD_HASH}
                element={<ResetPasswordPage />}
            />
            <Route
                path={ROUTES.DATA_PROTECTION_POLICY}
                element={<DataProtectionPolicy />}
            />
            <Route path={ROUTES.ACTIVATE_ACCOUNT} element={<SetupAccount />} />
            <Route path={ROUTES.SURVEY} element={<SurveyPage />} />
            {/* TODO: fix these routes */}
            {/* <Route
            path={ROUTES.OLD_SURVEY}
            render={(props) => (
                <Navigate
                    to={pathBuilder(
                        ROUTES.SURVEY,
                        ':id',
                        props.match.params.id
                    )}
                />
            )}
        /> */}
            {/* <Navigate from={`/${ROUTES.OLD_SURVEY}`} to={ROUTES.OLD_SURVEY} /> */}
            <Route path={ROUTES.FEEDBACK} element={<FeedbackLoopPage />} />
            <Route path={ROUTES.TBD} element={<ToBeDefined />} />
            <Route
                path="*"
                element={
                    <PrivateRoute>
                        <Layout />
                    </PrivateRoute>
                }
            />
            {/* <PrivateRoute path="" component={Layout} /> */}
        </Routes>
    </BrowserRouter>
);

export const App =
    import.meta.env.NODE_ENV !== 'production'
        ? AppContainer
        : Sentry.withProfiler(AppContainer);
