/** @jsxImportSource @emotion/react */
import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { css, IconButton, Theme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ROUTES } from '../../../../modules/navigation/enums/routes.enum';
import { NavLink } from 'react-router-dom';

interface ChipData {
    key: number;
    label: string;
    route?: any;
}

const styles = {
    menu: (theme: Theme) => css`
        margin-right: ${theme.spacing(1)};
    `,
    link: (theme: Theme) => css`
        text-decoration: none;
        color: ${theme.palette.text.primary};
    `,
};

export default function SimpleMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [chipData] = React.useState<ChipData[]>([
        { key: 0, label: 'Projects', route: ROUTES.PROJECTS },
        { key: 1, label: 'Analytics', route: ROUTES.ANALYTICS },
        { key: 2, label: 'Account Settings', route: ROUTES.ACCOUNT_SETTINGS },
        { key: 3, label: 'Super Admin', route: ROUTES.SUPER_ADMIN },
        // { key: 4, label: 'Pulse TEST', route: ROUTES.PULSE },
    ]);

    return (
        <div>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                css={styles.menu}
                size="large">
                <MenuIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {chipData.map((data) => {
                    return (
                        <MenuItem key={data.key}>
                            <NavLink
                                to={data.route}
                                css={styles.link}
                                onClick={handleClose}
                            >
                                {data.label}
                            </NavLink>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
