/** @jsxImportSource @emotion/react */
import { Box, css, LinearProgress, Theme, Typography } from '@mui/material';
import { IBrandingSettings } from 'modules/pulse-answering/models/feedback-loop-models';

const useStyles = {
    root: (theme: Theme) => css`
        background-color: ${theme.palette.common.white};
    `,
    progress: (primary: string) => (theme: Theme) => css`
        background-color: ${theme.palette.secondary.main};
        & > .MuiLinearProgress-barColorPrimary {
            background-color: ${primary ? primary : theme.palette.primary.main};
        }
    `,
    logo: (theme: Theme) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-height: 64px;
        margin-right: ${theme.spacing(2)};
        ${theme.breakpoints.down('sm')} {
            margin-right: ${theme.spacing(1)};
            max-height: 44px;
        }
    `,
    container: (background: string) => (theme: Theme) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${theme.spacing(3)};
        background: ${background ? background : theme.palette.primary.main};
        ${theme.breakpoints.down('sm')} {
            padding: ${theme.spacing(2)};
            justify-content: space-between;
        },
    `,
};

export interface SurveyHeaderProps {
    progressBarValue?: number;
    showProgressBar?: boolean;
    brandingSettings: IBrandingSettings;
}

export default function SurveyHeader({
    progressBarValue,
    showProgressBar = true,
    brandingSettings,
}: SurveyHeaderProps) {
    const props = {
        primaryColor: `#${brandingSettings.primary_color.replace('#', '')}`,
        headerBackground: `#${brandingSettings.header_background_color.replace(
            '#',
            ''
        )}`,
        actionText: `#${brandingSettings.action_text_color.replace('#', '')}`,
    };

    return (
        <Box css={useStyles.root}>
            <Box css={useStyles.container(props.headerBackground)}>
                <img
                    src={brandingSettings?.logo_link || ''}
                    alt=""
                    css={useStyles.logo}
                />
                <Typography variant="h5">
                    {brandingSettings?.project_name || ''}
                </Typography>
            </Box>
            {showProgressBar && (
                <LinearProgress
                    css={useStyles.progress(props.primaryColor)}
                    variant="determinate"
                    value={progressBarValue || 0}
                />
            )}
        </Box>
    );
}
