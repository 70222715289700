/** @jsxImportSource @emotion/react */
import { subject } from '@casl/ability';
import {
    Box,
    Button,
    Container,
    DialogContent,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { UserRoleRadioButtons } from 'core/components/shared/UserRoleRadioButtons';
import { useCrud } from 'core/hooks/crud.hook';
import { useSession } from 'core/hooks/session.hook';
import { Form, useFormikContext } from 'formik';
import { AccountAutocomplete } from 'modules/accounts/components/AccountAutocomplete';
import { DimensionsProvider } from 'modules/categories/components/DimensionsProvider';
import { DimenstionInputs } from 'modules/categories/components/DimenstionInputs';
import { Can } from 'modules/login/components/Can';
import {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { IAutocompleteDefaultOption } from '../../../core/components/ImplementPulseAutoComplete';
import { IUser, USER_ROLE } from '../models/user.model';
import { IUserFormik, UserFormContext } from './UserFormProvider';
import { Theme } from '@mui/material';
import { css } from '@mui/material/styles';

const styles = {
    form: css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
    `,
    selectWrap: css`
        position: relative;
    `,
    modalTitle: (theme: Theme) => css`
        padding: ${theme.spacing(6)} 0;
        ${theme.breakpoints.down('md')} {
            padding: ${theme.spacing(3)} 0;
        }
    `,
    modalContent: css`
        padding: 0;
    `,
    modalActions: (theme: Theme) => css`
        display: flex;
        justify-content: space-between;
        padding: ${theme.spacing(2)} ${theme.spacing(4)};
        background-color: #f4f5f7;
        align-items: center;
        width: 100%;
        ${theme.breakpoints.down('sm')} {
            flex-direction: column;
            padding: ${theme.spacing(2)} ${theme.spacing(1)};
        }
    `,
    inputsWrapper: css`
        overflow-y: auto;
    `,
    buttonWrap: (theme: Theme) => css`
        display: flex;
        ${theme.breakpoints.down('sm')} {
            flex-direction: column;
            margin-top: ${theme.spacing(2)};
        }
        & button {
            &:last-child {
                margin-left: ${theme.spacing(2)};
                ${theme.breakpoints.down('sm')} {
                    margin-left: 0;
                    margin-top: ${theme.spacing(2)};
                }
            }
            ${theme.breakpoints.down('sm')} {
                width: 100%;
            }
        }
    `,
    accSelect: (theme: Theme) => css`
        ${theme.breakpoints.down('md')} {
            margin-top: ${theme.spacing(2)};
        }
    `,
};

export const UserForm: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);

    const { me, abilities } = useSession();
    const { close, open, userId } = useContext(UserFormContext);
    const [isDeleting, setIsDeleting] = useState(false);

    const { fetchById } = useCrud<IUser>();

    useEffect(() => {
        userId && fetchById(userId).then((user) => user && open(user));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const {
        values,
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        submitForm,
        dirty,
        isValid,
        setFieldValue,
    } = useFormikContext<IUserFormik>();

    const { deleteMultiple } = useCrud();

    const onDelete = useCallback(async () => {
        if (userId) {
            setIsDeleting(true);
            await deleteMultiple([userId], { shouldFetchAfterSuccess: true });
            setIsDeleting(false);
            close();
        }
    }, [deleteMultiple, userId, close]);

    const selectedAccount: IAutocompleteDefaultOption | null = useMemo(
        () =>
            !values.organizationId
                ? null
                : {
                      id: values.organizationId,
                      name: values.organizationName,
                  },
        [values.organizationId, values.organizationName]
    );

    return (
        <>
            <DialogContent css={styles.modalContent}>
                <Form css={styles.form}>
                    <div css={styles.inputsWrapper}>
                        <Container maxWidth="md">
                            <Typography
                                variant="h2"
                                align="center"
                                css={styles.modalTitle}
                            >
                                {userId ? t('edit-user') : t('create-user')}
                            </Typography>
                            <TextField
                                autoFocus
                                label={t('fullname-placeholder')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="name"
                                type="text"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                error={!!errors.name}
                                disabled={
                                    !!userId &&
                                    abilities.cannot(
                                        'update',
                                        subject('IUser', {
                                            id: userId,
                                        }),
                                        'name'
                                    )
                                }
                                helperText={t('enter-fullname-placeholder')}
                            />
                            <TextField
                                label={t('email-placeholder')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                type="text"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={!!errors.email}
                                disabled={
                                    !!userId &&
                                    abilities.cannot(
                                        'update',
                                        subject('IUser', {
                                            id: userId,
                                        }),
                                        'email'
                                    )
                                }
                                helperText={t('enter-email-placeholder')}
                            />
                            <Box mb={2} />
                            <Box mb={6}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        css={styles.accSelect}
                                    >
                                        <AccountAutocomplete
                                            label={t('account')}
                                            onSelect={async (item) => {
                                                setFieldValue(
                                                    'organizationId',
                                                    item?.id ?? null
                                                );
                                                setFieldValue(
                                                    'organizationName',
                                                    item?.name ?? ''
                                                );
                                            }}
                                            value={selectedAccount}
                                            disabled={abilities.cannot(
                                                'update',
                                                subject('IUser', {
                                                    id: userId,
                                                }),
                                                'organizationId'
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mb={6}>
                                <UserRoleRadioButtons
                                    onChangeHandler={(value) =>
                                        setFieldValue('role', value)
                                    }
                                    radioButtonValue={values.role}
                                    disabled={
                                        !!userId &&
                                        abilities.cannot(
                                            'update',
                                            subject('IUser', {
                                                id: userId,
                                            }),
                                            'role'
                                        )
                                    }
                                />
                            </Box>
                            {values.role === USER_ROLE.PORTFOLIO_ADMIN &&
                                (me?.organizationId !==
                                values.organizationId ? (
                                    <span>
                                        You are unable to pick categories since
                                        you are from a different account.
                                    </span> // TODO: is this a realistic situation? if yes, add translation or better content
                                ) : (
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                {t('specify-category')}
                                            </Typography>
                                            <Typography
                                                className="MuiTypography-badge"
                                                color="textSecondary"
                                            >
                                                {t('user-only-see')}
                                            </Typography>
                                        </Grid>
                                        <DimensionsProvider>
                                            <DimenstionInputs
                                                onChange={async (
                                                    dimension,
                                                    category
                                                ) => {
                                                    setFieldValue(
                                                        `categoriesByDimensionId.${dimension.id}`,
                                                        category
                                                    );
                                                }}
                                                emptyName={t('all')}
                                                categoriesByDimensionId={
                                                    values.categoriesByDimensionId
                                                }
                                                disabled={abilities.cannot(
                                                    'update',
                                                    'IUser',
                                                    'categories'
                                                )}
                                            />
                                        </DimensionsProvider>
                                    </Grid>
                                ))}
                        </Container>
                    </div>
                    <Box css={styles.modalActions}>
                        <Button type="reset" variant="text" onClick={close}>
                            {t('cancel')}
                        </Button>

                        <div css={styles.buttonWrap}>
                            {userId ? (
                                <Can I="delete" a="IUser">
                                    <Button
                                        style={{
                                            border: '1px solid red',
                                            color: 'red',
                                        }}
                                        disabled={isDeleting || isSubmitting}
                                        variant="outlined"
                                        disableElevation
                                        onClick={onDelete}
                                    >
                                        {t('delete-user')}
                                    </Button>
                                </Can>
                            ) : (
                                <Button
                                    disabled={
                                        isDeleting ||
                                        isSubmitting ||
                                        !dirty ||
                                        !isValid
                                    }
                                    color="secondary"
                                    variant="contained"
                                    disableElevation
                                    onClick={() => {
                                        setFieldValue('openNewUser', true);
                                        submitForm();
                                    }}
                                >
                                    {t('create-new-and-add-another')}
                                </Button>
                            )}

                            <Button
                                disabled={
                                    isDeleting ||
                                    isSubmitting ||
                                    !dirty ||
                                    !isValid
                                }
                                color="primary"
                                variant="contained"
                                disableElevation
                                onClick={submitForm}
                            >
                                {userId ? t('save-changes') : t('create-user')}
                            </Button>
                        </div>
                    </Box>
                </Form>
            </DialogContent>
        </>
    );
};
