/** @jsxImportSource @emotion/react */
import { Box, Button, css, Grid, Link, Theme, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import LoopIcon from '@mui/icons-material/Loop';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ShareIcon from '@mui/icons-material/Share';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { FC, useContext } from 'react';
import { MultiLanguageSupportContext } from '../../../core/components/MultiLanguageSupportProvider';
import { ROUTES } from '../../navigation/enums/routes.enum';

const styles = {
    root: () => css`
        max-width: 720px;
        margin: 0 auto;
    `,
    flexCenter: () => css`
        display: flex;
        justify-content: center;
    `,
    iconContainer: (theme: Theme) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        ${theme.breakpoints.up('sm')} {
            flex-direction: row;
        }
    `,
    iconStep: (theme: Theme) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: ${theme.spacing(2)};
    `,
    icon: (theme: Theme) => css`
        color: #cdbaf3;
        font-size: 36px;
        margin-bottom: ${theme.spacing(2)};
    `,
    iconOverline: () => css`
        display: flex;
        width: 200px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `,
    icon2: (theme: Theme) => css`
        color: ${theme.palette.secondary.main};
        font-size: 36px;
        margin-bottom: ${theme.spacing(2)};
    `,
    modalFooter: (theme: Theme) => css`
        background-color: #f4f5f7;
        display: flex;
        justify-content: flex-end;
        padding: ${theme.spacing(3)};
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    `,
    closeButton: (theme: Theme) => css`
        position: absolute;
        top: ${theme.spacing(2)};
        right: ${theme.spacing(2)};
        border-radius: 50%;
        min-width: 0;
        padding: ${theme.spacing(1)};
        ${theme.breakpoints.down('md')} {
            display: none;
        }
    `,
};

interface IHelpFeedbackProps {
    closeHelp: () => void;
}

export const HelpFeedbackModalContent: FC<IHelpFeedbackProps> = ({
    closeHelp,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);

    return (
        <>
            <Box css={styles.root}>
                {/* close btn with icon */}
                <Button
                    variant="text"
                    css={styles.closeButton}
                    onClick={closeHelp}
                >
                    <CloseIcon color="disabled" />
                </Button>
                {/* close btn with icon - end*/}

                {/* TODO: translations for whole component */}
                <Box css={styles.flexCenter} mb={4}>
                    <Typography variant="h2">Help & Feedback</Typography>
                </Box>
                <Box css={styles.flexCenter} mb={1}>
                    <Typography variant="h6" color="textSecondary">
                        How it works:
                    </Typography>
                </Box>
                <Box css={styles.flexCenter} mb={3} px={8}>
                    <Typography variant="body2" align="center">
                        ProjectPulse helps you stay in tune with people, build
                        engagement and create more value. You can now setup your
                        projects, and edit projects created on your behalf.
                    </Typography>
                </Box>
                <Box css={styles.iconContainer} mb={3}>
                    <Box css={styles.iconStep}>
                        <CastConnectedIcon css={styles.icon} />
                        <Typography
                            className="MuiTypography-badge"
                            align="center"
                        >
                            Onboard people to contribute
                        </Typography>
                    </Box>
                    <ArrowRightAltIcon color="secondary" />
                    <Box css={styles.iconStep}>
                        <SettingsApplicationsIcon css={styles.icon} />
                        <Typography
                            className="MuiTypography-badge"
                            align="center"
                        >
                            Setup your Pulse and schedule
                        </Typography>
                    </Box>
                    <ArrowRightAltIcon color="secondary" />
                    <Box css={styles.iconStep}>
                        <DescriptionIcon css={styles.icon} />
                        <Typography
                            className="MuiTypography-badge"
                            align="center"
                        >
                            Review feedback and take action together
                        </Typography>
                    </Box>
                    <LoopIcon color="secondary" />
                    <Box css={styles.iconStep}>
                        <TrendingUpIcon css={styles.icon} />
                        <Typography
                            className="MuiTypography-badge"
                            align="center"
                        >
                            Track impact over time
                        </Typography>
                    </Box>
                </Box>
                <Box css={styles.flexCenter} mb={4} px={1}>
                    <Typography variant="body2" align="center">
                        Remember to introduce the idea with ProjectPulse for
                        participants beforehand, and also how you&apos;re going
                        to follow-up as you go. If you’re feeling stuck you can
                        always reach out to get help at{' '}
                        <Link
                            href={
                                'mailto:' +
                                import.meta.env.VITE_APP_SUPPORT_EMAIL
                            }
                        >
                            {import.meta.env.VITE_APP_SUPPORT_EMAIL}
                        </Link>
                    </Typography>
                </Box>
                <Box css={styles.flexCenter} mb={1}>
                    <Typography variant="h6" color="textSecondary">
                        We&apos;re ready to assist ...
                    </Typography>
                </Box>
                <Box css={styles.flexCenter} mb={4} px={8}>
                    <Typography variant="body2" align="center">
                        Would you like to report a bug, suggest a new feature or
                        just give us some feedback? We’d love to hear from you!
                        We will kill the bugs, and do our best to build any new
                        feature you need to succeed. Contact us at{' '}
                        <Link href="mailto:feedback@projectpulses.com">
                            feedback@projectpulses.com
                        </Link>
                    </Typography>
                </Box>
                <Grid container>
                    <Grid item sm={6}>
                        <Box
                            css={styles.flexCenter}
                            flexDirection="column"
                            alignItems="center"
                            mb={4}
                        >
                            <Box css={styles.iconOverline} mb={2}>
                                <VerifiedUserIcon
                                    css={styles.icon2}
                                    color="disabled"
                                />
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Anonymity and policy
                                </Typography>
                            </Box>
                            <Box px={3}>
                                <Typography variant="body2" align="center">
                                    ProjectPulse collects responses in the
                                    groups you define and does not reveal which
                                    responses are associated with which person.
                                    Data will appear in your reports, but you
                                    won&apos;t know who said what. Be respectful
                                    of participants who want to be anonymous by
                                    making sure to have multiple participants in
                                    each group. Click to read the{' '}
                                    <Link
                                        href={ROUTES.DATA_PROTECTION_POLICY}
                                        target="_blank"
                                    >
                                        Data Protection Policy.
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box
                            css={styles.flexCenter}
                            flexDirection="column"
                            alignItems="center"
                            mb={4}
                        >
                            <Box css={styles.iconOverline} mb={2}>
                                <ShareIcon
                                    css={styles.icon2}
                                    color="disabled"
                                />
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Sharing of information
                                </Typography>
                            </Box>
                            <Box px={3}>
                                <Typography variant="body2" align="center">
                                    Pulse data from your projects will,
                                    naturally, be provided for you to share in
                                    the project. Data might also be accessible
                                    by other users in your organization,
                                    typically senior level managers. This does
                                    not apply to feedback marked by participants
                                    for your eyes only. Please contact your
                                    admin directly to understand how data are
                                    shared in your organization.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box css={styles.modalFooter}>
                <Button variant="contained" color="primary" onClick={closeHelp}>
                    {t('close')}
                </Button>
            </Box>
        </>
    );
};
