import { useObservable } from '@mindspace-io/react';
import { useContext } from 'react';
import { EntitiesContext } from '../constants/entities.context';

export interface IUsePagination {
    currentPageSize: number | undefined;
    currentPageNumber: number | undefined;
    totalNumberOfEntities: number | null | undefined;
}

export const usePagination = (): IUsePagination => {
    const { query, store } = useContext(EntitiesContext);
    const {
        total,
        ui: { pageNumber, pageSize },
    } = store.getValue();

    const [currentPageSize] = useObservable(query.pageSize$, pageSize);

    const [currentPageNumber] = useObservable(query.pageNumber$, pageNumber);

    const [totalNumberOfEntities] = useObservable(query.total$, total);

    return {
        currentPageSize,
        currentPageNumber,
        totalNumberOfEntities,
    };
};
