/** @jsxImportSource @emotion/react */
import {
    Box,
    Container,
    css,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { IStarRatingProps } from 'core/interfaces/survey-answering.interface';
import { Form, useFormikContext } from 'formik';
import { FC, useContext } from 'react';
import { filterTranslatesBuilder } from './filterTranslatesBuilderHelper';

const useStyles = {
    root: (theme: Theme) => css`
        background-color: ${theme.palette.background.paper};
    `,
    ratingContainer: (theme: Theme) => css`
        max-width: 864px;
        ${theme.breakpoints.down('sm')} {
            max-width: 100%;
            padding: 0;
        }
    `,
    stepIntro: (theme: Theme) => css`
        padding: 48px 0;
        ${theme.breakpoints.down('sm')} {
            padding: 24px 0;
        }
    `,
    stepHeading: (theme: Theme) => css`
        ${theme.breakpoints.down('sm')} {
            font-size: 20px;
            line-height: 24px;
        }
    `,
    ratingBox: (theme: Theme) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid ${theme.palette.divider};
        border-radius: ${theme.spacing(0.5)};
        background-color: ${theme.palette.common.white};
        padding: 24px;
        position: relative;
        ${theme.breakpoints.down('md')} {
            flex-direction: column;
            justify-content: center;
            padding: 8px;
        }
    `,
    bottomAction: (theme: Theme) => css`
        height: ${theme.spacing(12)};
        position: sticky;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid ${theme.palette.divider};
        z-index: 100;
        background-color: ${theme.palette.common.white};
    `,
    form: css`
        width: 100%;
    `,
    ratingText: (theme: Theme) => css`
        max-width: 60%;
        text-align: left;
        font-size: 19px;
        ${theme.breakpoints.down('md')} {
            max-width: 100%;
            text-align: center;
        }
    `,
    ratingIcon: (theme: Theme) => css`
        margin-left: 20px;
        ${theme.breakpoints.down('md')} {
            margin-left: 0;
            margin-top: 10px;
        }

        & .MuiSvgIcon-root {
            width: 60px;
            height: 60px;
            /* // pointer events none solves the issue for now.
            // For orpostiginal  to issue please check: https://github.com/mui/material-ui/issues/32557 */
            pointer-events: auto;

            ${theme.breakpoints.down('sm')} {
                width: 67px;
            }
        }
    `,
    questionNumber: (theme: Theme) => css`
        color: #c1c7d0;
        margin-right: 5px;
        ${theme.breakpoints.down('md')} {
            display: none;
            font-size: 12px;
            line-height: 16px;
            position: absolute;
            top: 8px;
            right: 8px;
            margin-right: 0;
        }
    `,
};

export const StarRating: FC<IStarRatingProps> = ({
    surveyQuestions,
    surveyLabelTranslations,
}) => {
    const { t } = useContext(MultiLanguageSupportContext);
    const { values, setFieldValue } = useFormikContext<{
        [key: string]: number;
    }>();

    const filterTranslates = filterTranslatesBuilder(surveyLabelTranslations);

    const theme = useTheme();
    const breakpointSm = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box css={useStyles.root}>
            <Form css={useStyles.form}>
                <Container>
                    <Box css={useStyles.stepIntro}>
                        <Typography
                            variant="h2"
                            align="center"
                            css={useStyles.stepHeading}
                        >
                            {filterTranslates(
                                'pulseQuestionsHeadline',
                                t('pulse-questions-headline')
                            )}
                        </Typography>
                    </Box>

                    <Container css={useStyles.ratingContainer}>
                        {surveyQuestions.map(
                            ({ id, translated_phrase }, index) => (
                                <Box css={useStyles.ratingBox} mb={1} key={id}>
                                    <Typography
                                        variant="h6"
                                        css={useStyles.ratingText}
                                    >
                                        <span css={useStyles.questionNumber}>
                                            {breakpointSm
                                                ? `${index + 1} / ${
                                                      surveyQuestions.length
                                                  }`
                                                : `${index + 1}.`}
                                        </span>
                                        {translated_phrase}
                                    </Typography>
                                    <Rating
                                        css={useStyles.ratingIcon}
                                        precision={0.5}
                                        name={id.toString()}
                                        value={
                                            values[id] ? values[id] / 2 : null
                                        }
                                        onChange={(_e, value) => {
                                            setFieldValue(
                                                id.toString(),
                                                (value ?? 0) * 2
                                            );
                                        }}
                                        emptyIcon={
                                            <StarIcon
                                                style={{ opacity: 0.5 }}
                                                fontSize="inherit"
                                            />
                                        }
                                    />
                                </Box>
                            )
                        )}
                    </Container>
                </Container>
            </Form>
        </Box>
    );
};
