import { EntitiesContext } from 'core/constants/entities.context';
import { FC, useContext, useEffect } from 'react';

export const WithCurrentPageListener = <T,>(
    WrappedComponent: FC<T>,
    unsubscribeTimoutPeriod?: number
): FC<T> => {
    const HocComponent = (props: T) => {
        const {
            query: { startCurrentPageListener, stopCurrentPageListener },
        } = useContext(EntitiesContext);

        useEffect(() => {
            startCurrentPageListener();

            return () => {
                stopCurrentPageListener(unsubscribeTimoutPeriod);
            };
        }, [startCurrentPageListener, stopCurrentPageListener]);

        return <WrappedComponent {...(props as any)} />;
    };

    Object.defineProperty(HocComponent, 'name', {
        value: `WithListContainer(${WrappedComponent.name})`,
        configurable: true,
    });

    return HocComponent;
};
