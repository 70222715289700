/** @jsxImportSource @emotion/react */
import { Box, css, SelectChangeEvent, Theme } from '@mui/material';

import { useCurrentPage } from 'core/hooks/current-page.hook';
import { pageSizeOptions } from '../constants/page-size-options';
import { usePagination } from '../hooks/pagination.hook';
import { ErrorBoundary } from './ErrorBoundary';

import { Dropdown } from 'shared/SharedComponents/Dropdown';
import { Pagination } from '@mui/material';

const styles = {
    root: (theme: Theme) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Nunito Sans';
        margin-top: ${theme.spacing(1)};
        margin-bottom: ${theme.spacing(2)};
    `,
    right: (theme: Theme) => css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & > *:first-of-type {
            margin-right: ${theme.spacing(2)};
        }
    `,
    button: (theme: Theme) => css`
        padding: ${theme.spacing(1.5)} 0;
        min-width: ${theme.spacing(3)};
    `,
    typoButton: css`
        max-height: 12px;
    `,
};

export const EntityListPagination = () => {
    const { setPageNumber, setPageSize } = useCurrentPage();
    const { currentPageSize, currentPageNumber, totalNumberOfEntities } =
        usePagination();

    if (!currentPageSize || !currentPageNumber) {
        return null;
    }

    const numberOfPreviousItems = (currentPageNumber - 1) * currentPageSize;

    const upperPageLimit = !totalNumberOfEntities
        ? null
        : numberOfPreviousItems + currentPageSize >= totalNumberOfEntities
          ? totalNumberOfEntities
          : numberOfPreviousItems + currentPageSize;

    return (
        <ErrorBoundary>
            <div css={styles.root}>
                <Box display="flex" alignItems={'center'}>
                    <span color="textSecondary" style={{ marginRight: '10px' }}>
                        Rows per page:{' '}
                    </span>
                    <Dropdown
                        currentValue={currentPageSize}
                        availableOptions={pageSizeOptions.map((value) => ({
                            displayValue: value.toString(),
                            value,
                        }))}
                        handleChange={(event: SelectChangeEvent<unknown>) => {
                            setPageSize(event.target.value as number);
                        }}
                    />
                </Box>
                <div css={styles.right}>
                    <div>
                        {numberOfPreviousItems + 1}-{upperPageLimit} of{' '}
                        {totalNumberOfEntities}
                    </div>
                    {!totalNumberOfEntities ? null : (
                        <Pagination
                            page={currentPageNumber}
                            count={Math.ceil(
                                totalNumberOfEntities / currentPageSize
                            )}
                            showFirstButton
                            showLastButton
                            onChange={(event, value) => setPageNumber(value)}
                        />
                        //TODO: remove old pagination
                        // <Paginator
                        //     pageSize={currentPageSize}
                        //     pageNumber={currentPageNumber}
                        //     pageSizeOptions={pageSizeOptions}
                        //     totalNumberOfItems={totalNumberOfEntities}
                        //     buttonIdentifiers={[
                        //         'first',
                        //         'prev',
                        //         'next',
                        //         'last',
                        //     ]}
                        //     buttonIdentifierLabels={{
                        //         first: t('first'),
                        //         last: t('last'),
                        //     }}
                        //     selectPageNumber={(pageNumber) => {
                        //         switch (pageNumber) {
                        //             case currentPageNumber:
                        //                 fetch();
                        //                 break;
                        //             default: {
                        //                 setPageNumber(pageNumber);
                        //             }
                        //         }
                        //     }}
                        //     renderSelectPageNumberButton={({
                        //         label,
                        //         ...props
                        //     }) => (
                        //         <Button
                        //             size="small"
                        //             color="default"
                        //             variant="text"
                        //             disableElevation
                        //             css={styles.button}
                        //             {...props}
                        //         >
                        //             {getIconComponent(label)}
                        //         </Button>
                        //     )}
                        //     renderSelectPageSizeDropdown={() => <></>}
                        // />
                    )}
                </div>
            </div>
        </ErrorBoundary>
    );
};
