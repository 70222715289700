import {
    Box,
    Button,
    Container,
    Grid,
    List,
    ListItem,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import SurveyHeader from 'core/components/shared/pulse-survey/surveyHeader';
import { apiService } from 'core/services/apiService';
import { requiredField } from 'core/validations';
import { Form, Formik } from 'formik';
import {
    IBrandingSettings,
    IFeedbackReturn,
    ISendMessage,
} from 'modules/pulse-answering/models/feedback-loop-models';
import { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FeedbackAnsweredSvg } from 'styles/assets/svg/feedback-answered';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    content: {
        minHeight: `calc(100vh - 212px)`,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
        backgroundColor: theme.palette.background.paper,
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        left: 0,
        bottom: 0,
        zIndex: 10,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
    },
    buttonWrap: {
        '& button': {
            marginLeft: theme.spacing(2),
        },
        '& > :first-of-type': {
            marginLeft: '0',
        },
    },
    admin: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(2),
        '& $avatar': {
            marginRight: theme.spacing(3),
            backgroundColor: theme.palette.info.light,
            color: theme.palette.common.white,
        },
        '& $message': {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.common.white,
            borderRadius: `0 ${theme.spacing(0.5)} ${theme.spacing(
                0.5
            )} ${theme.spacing(0.5)}`,
        },
    },
    user: {
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(2),
        '& $avatar': {
            order: 2,
            marginLeft: theme.spacing(3),
        },
        '& $message': {
            backgroundColor: theme.palette.divider,
            borderRadius: `${theme.spacing(0.5)} 0 ${theme.spacing(
                0.5
            )} ${theme.spacing(0.5)}`,
        },
    },
    avatar: {
        height: theme.spacing(6),
        width: theme.spacing(6),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e5e5e5',
        borderRadius: '50%',
        flex: '0 0 48px',
        marginTop: '8px',
    },
    message: {
        width: 'calc(100% - 144px)',
        padding: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    input: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down('md')]: {
            margin: `0 0 ${theme.spacing(2)} 0`,
        },
    },
    messageDate: {
        margin: '0',
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
            marginBottom: '5px',
        },
    },
    messageText: {
        margin: '0',
        width: 'calc(100% - 150px)',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
}));

export const FeedbackLoopPage: FC = () => {
    const { id } = useParams<string>();
    const { t } = useContext(MultiLanguageSupportContext);
    const [brandingSettings, setBrandingSettings] =
        useState<IBrandingSettings>();
    const [returnedFeedbacks, setReturnedFeedbacks] =
        useState<IFeedbackReturn>();

    const [feedbackAnswered, setFeedbackAnswered] = useState(false);
    const classes = useStyles();
    const initialValues = {
        message: '',
    };
    const validationSchema = Yup.object().shape({
        message: requiredField,
    });

    useEffect(() => {
        if (id) {
            apiService.get(`/feedbacks/${id}`).then(({ data }) => {
                setReturnedFeedbacks(data);
            });
        }
    }, [id]);

    useEffect(() => {
        if (returnedFeedbacks) {
            apiService
                .get(
                    `/surveys/${returnedFeedbacks?.pulse_event_respondent?.id}/branding`
                )
                .then(({ data }) => {
                    setBrandingSettings(data);
                });
        }
    }, [returnedFeedbacks]);

    const formatDate = (date: any) => {
        const options: any = { month: 'long', year: 'numeric', day: 'numeric' };
        date = new Date(date);
        const time = date.toISOString().split('T')[1].slice(0, 5);
        const dateFormated = date.toISOString().split('T')[0];
        const newDateFirnated = new Date(dateFormated).toLocaleDateString(
            'en-US',
            options
        );
        return (
            <>
                {time}, {newDateFirnated}
            </>
        );
    };

    const onSubmitClick = (vals: ISendMessage) => {
        apiService
            .post(`feedbacks/${id}/reply`, vals)
            .then(() => setFeedbackAnswered(true));
    };

    return (
        <>
            {brandingSettings ? (
                <SurveyHeader
                    progressBarValue={100}
                    brandingSettings={brandingSettings}
                ></SurveyHeader>
            ) : null}
            {!feedbackAnswered ? (
                <>
                    <Box className={classes.root}>
                        <Container className={classes.content}>
                            <Box py={6}>
                                <Typography variant="h3" align="center">
                                    {t('private-feedback-loop')}
                                </Typography>
                            </Box>
                            <List>
                                {returnedFeedbacks?.message.replace(
                                    /\s/g,
                                    ''
                                ) ? (
                                    <ListItem className={classes.user}>
                                        <Box className={classes.avatar}>
                                            <Tooltip
                                                title={
                                                    returnedFeedbacks.respondent
                                                        ?.name ?? 'Respondent'
                                                }
                                            >
                                                <span> {t('you')}</span>
                                            </Tooltip>
                                        </Box>

                                        <Box className={classes.message}>
                                            <p className={classes.messageDate}>
                                                {formatDate(
                                                    returnedFeedbacks
                                                        .pulse_event_respondent
                                                        .created_at
                                                )}
                                            </p>
                                            <p className={classes.messageText}>
                                                {returnedFeedbacks.message}
                                            </p>
                                        </Box>
                                    </ListItem>
                                ) : null}
                                {returnedFeedbacks?.feedback_replies
                                    .filter((item) => !!item.message)
                                    .map((item, idx) => {
                                        return (
                                            item.message && (
                                                <ListItem
                                                    key={idx}
                                                    className={
                                                        item.reply_from ===
                                                        'respondent'
                                                            ? classes.user
                                                            : classes.admin
                                                    }
                                                >
                                                    {item.reply_from ===
                                                    'respondent' ? (
                                                        <Box
                                                            className={
                                                                classes.avatar
                                                            }
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    returnedFeedbacks
                                                                        .respondent
                                                                        ?.name ??
                                                                    'Respondent'
                                                                }
                                                            >
                                                                <span>
                                                                    {t('you')}
                                                                </span>
                                                            </Tooltip>
                                                        </Box>
                                                    ) : (
                                                        <Box
                                                            className={
                                                                classes.avatar
                                                            }
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    returnedFeedbacks
                                                                        .manager
                                                                        .name
                                                                }
                                                            >
                                                                <span>
                                                                    {t('pm')}
                                                                </span>
                                                            </Tooltip>
                                                        </Box>
                                                    )}
                                                    <Box
                                                        className={
                                                            classes.message
                                                        }
                                                    >
                                                        <p
                                                            className={
                                                                classes.messageDate
                                                            }
                                                        >
                                                            {formatDate(
                                                                item.created_at
                                                            )}
                                                        </p>
                                                        <p
                                                            className={
                                                                classes.messageText
                                                            }
                                                        >
                                                            {item.message}
                                                        </p>
                                                    </Box>
                                                </ListItem>
                                            )
                                        );
                                    })}
                            </List>
                        </Container>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={3}
                    >
                        <Formik
                            enableReinitialize
                            validateOnMount={true}
                            validateOnChange={true}
                            validateOnBlur={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, actions) => {
                                onSubmitClick(values);
                                actions.resetForm();
                            }}
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    isValid,
                                } = props;
                                return (
                                    <Form>
                                        <Grid container spacing={3}>
                                            <Box className={classes.buttons}>
                                                <TextField
                                                    className={classes.input}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    id="message"
                                                    name="message"
                                                    value={values.message}
                                                    error={!!errors.message}
                                                    label={t(
                                                        'type-message-here'
                                                    )}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disableElevation
                                                    disabled={!isValid}
                                                    endIcon={
                                                        <ArrowForwardIosIcon />
                                                    }
                                                >
                                                    {t('submit')}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Box>
                </>
            ) : (
                <>
                    <Box className={classes.root}>
                        <Container className={classes.content}>
                            <Box py={6}>
                                <Typography variant="h3" align="center">
                                    {t('your-answers-have-been-registered')}
                                    <br />
                                    <FeedbackAnsweredSvg />
                                </Typography>
                            </Box>
                        </Container>
                    </Box>
                </>
            )}
        </>
    );
};
