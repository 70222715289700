import { Box, Typography, Button, Theme } from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from '@mui/styles';
import css from 'styles/material-ui/cssVars';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        backgroundColor: css.extraColors.text.darkPrimary,
        border: '1px solid #DFE1E6',
        borderRadius: theme.spacing(0.5),
        '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label':
            {
                backgroundColor: 'transparent',
            },
        '& .MuiTreeItem-label:hover': {
            backgroundColor: 'transparent',
        },
    },
    treeItem: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        border: '1px solid #DFE1E6',
        '& .MuiTreeItem-content': {
            marginBottom: theme.spacing(1),
        },
        '& .MuiTreeItem-root': {
            backgroundColor: '#FFFFFF',
            '& .MuiTreeItem-root': {
                backgroundColor: '#FAFBFC',
                '& .MuiTreeItem-root': {
                    backgroundColor: '#FFFFF',
                    '& .MuiTreeItem-root': {
                        backgroundColor: '#F4F5F7',
                        '& .MuiTreeItem-root': {
                            backgroundColor: '#FFFFFF',
                            '& .MuiTreeItem-root': {
                                backgroundColor: '#F4F5F7',
                            },
                        },
                    },
                },
            },
        },
    },
    treeItemBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

interface RenderTree {
    id: string;
    name: string;
    children?: RenderTree[];
    isRoot?: boolean;
}

const mockData: RenderTree = {
    id: 'root',
    name: 'Component Root',
    isRoot: true,
    children: [
        {
            id: '1',
            name: 'Geography',
            children: [
                {
                    id: '2',
                    name: 'Name 2',
                    children: [
                        {
                            id: '3',
                            name: 'Name 3',
                            children: [
                                {
                                    id: '4',
                                    name: 'Name 4',
                                    children: [
                                        {
                                            id: '5',
                                            name: 'Name 5',
                                        },
                                        {
                                            id: '6',
                                            name: 'Name 6',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: '7',
                    name: 'Another Child',
                },
                {
                    id: '8',
                    name: 'Yet another child',
                },
            ],
        },
    ],
};

export default function CategoriesTreeView() {
    const classes = useStyles();

    const renderTree = (nodes: RenderTree) => (
        <TreeItem
            className={classes.treeItem}
            key={nodes.id}
            itemId={nodes.id}
            label={
                <Box className={classes.treeItemBox}>
                    <Typography variant="h5">{nodes.name}</Typography>
                    {nodes.isRoot && (
                        <Button startIcon={<AddIcon />}>
                            <Typography variant="subtitle2" className="">
                                Add Entry
                            </Typography>
                        </Button>
                    )}
                </Box>
            }
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    return (
        <SimpleTreeView
            className={classes.root}
            slots={{
                collapseIcon: ExpandMoreIcon,
                expandIcon: ChevronRightIcon,
            }}
            defaultExpandedItems={['root']}
        >
            {renderTree(mockData)}
        </SimpleTreeView>
    );
}
