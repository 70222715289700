/** @jsxImportSource @emotion/react */
import {
    Box,
    Container,
    css,
    Switch,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ISurveyFeedbackProps } from 'core/interfaces/survey-answering.interface';
import { Form, useFormikContext } from 'formik';
import { useContext } from 'react';
import { filterTranslatesBuilder } from './filterTranslatesBuilderHelper';

const useStyles = {
    root: (theme: Theme) => css`
        background-color: ${theme.palette.background.paper};
    `,
    feedbackContainer: css`
        max-width: 660px;
    `,
    stepIntro: (theme: Theme) => css`
        padding: 48px 0;
        ${theme.breakpoints.down('sm')} {
            padding: 24px 0;
        }
    `,
    stepHeading: (theme: Theme) => css`
        ${theme.breakpoints.down('sm')} {
            font-size: 20px;
            line-height: 24px;
        }
    `,
    feedbackBox: (theme: Theme) => css`
        background-color: ${theme.palette.common.white};
        border: 1px solid ${theme.palette.divider};
        border-radius: ${theme.spacing(0.5)};
    `,
    form: css`
        width: 100%;
    `,
};

export const SurveyFeedback = ({
    surveyLabelTranslations,
}: ISurveyFeedbackProps) => {
    const { t } = useContext(MultiLanguageSupportContext);

    const { values, handleChange, setFieldValue } = useFormikContext<{
        [key: string]: string;
    }>();

    const filterTranslates = filterTranslatesBuilder(surveyLabelTranslations);

    return (
        <Box css={useStyles.root}>
            <Form css={useStyles.form}>
                <Container>
                    <Box css={useStyles.stepIntro}>
                        <Typography
                            variant="h2"
                            align="center"
                            css={useStyles.stepHeading}
                        >
                            {filterTranslates(
                                'feedbackHeadline',
                                t('feedback-headline')
                            )}
                        </Typography>
                    </Box>

                    <Container css={useStyles.feedbackContainer}>
                        <Box
                            css={useStyles.feedbackBox}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            p={2}
                            mb={2}
                        >
                            <TextField
                                variant="outlined"
                                placeholder={filterTranslates(
                                    'suggestionPlaceholder',
                                    t('suggestion-placeholder')
                                )}
                                multiline
                                rows={12}
                                fullWidth
                                name="text"
                                value={values.text}
                                onChange={handleChange}
                            ></TextField>
                            <Box display="flex" alignItems="center" mt={2}>
                                <Typography variant="body2">
                                    {filterTranslates(
                                        'privateFeedback',
                                        t('private-feedback')
                                    )}
                                </Typography>
                                <Switch
                                    color="primary"
                                    formNoValidate
                                    checked={!values.public}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'public',
                                            !e.target.checked
                                        );
                                    }}
                                    name="public"
                                    inputProps={{
                                        'aria-label': 'secondary checkbox',
                                    }}
                                />
                            </Box>
                        </Box>
                    </Container>
                </Container>
            </Form>
        </Box>
    );
};
