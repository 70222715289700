import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

export interface DropdownProps {
    label?: string;
    currentValue?: SelectProps['value'];
    availableOptions: {
        displayValue: string;
        value: string | number;
    }[];

    handleChange?: SelectProps['onChange'];
    [key: string]: any;
}

export const Dropdown = ({
    availableOptions,
    id,
    label,
    handleChange,
    currentValue,
}: DropdownProps) => {
    return (
        <FormControl>
            {label && <InputLabel id={id}>{label}</InputLabel>}
            <Select
                labelId={id}
                id={id + '-select'}
                value={currentValue}
                onChange={handleChange}
            >
                {availableOptions.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                        {option.displayValue}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
