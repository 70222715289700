import { TableCell, TableRow } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IListHeader {
    renderFirstColumn?: () => JSX.Element;
    renderLastColumn?: () => JSX.Element;
    children: ReactNode;
}

export const ListHeader: FC<IListHeader> = ({
    renderFirstColumn,
    renderLastColumn,
    children,
}) => (
    <TableRow>
        {renderFirstColumn && <TableCell>{renderFirstColumn()}</TableCell>}
        {children}
        {renderLastColumn && <TableCell>{renderLastColumn()}</TableCell>}
    </TableRow>
);
