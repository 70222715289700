/** @jsxImportSource @emotion/react */
import {
    Box,
    Container,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
    css,
    Theme,
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { ISuccessFactorsProps } from 'core/interfaces/survey-answering.interface';
import { useFormikContext } from 'formik';
import { FactorIcon } from 'modules/success-factors/components/FactorIcon';
import { FactorValue } from 'modules/success-factors/interfaces/FactorValue';
import { FC, useContext, useEffect } from 'react';
import { filterTranslatesBuilder } from './filterTranslatesBuilderHelper';

const useStyles = {
    root: (theme: Theme) => css`
        background-color: ${theme.palette.background.paper};
    `,

    content: css`
        min-height: calc(100vh - 212px);
        margin-bottom: 20px;
    `,

    factorsContainer: css`
        max-width: 950px;
    `,

    stepIntro: (theme: Theme) => css`
        padding: 48px 0;
        ${theme.breakpoints.down('sm')} {
            padding: 24px 0;
        }
    `,

    stepHeading: (theme: Theme) => css`
        ${theme.breakpoints.down('sm')} {
            font-size: 20px;
            line-height: 24px;
        }
    `,

    factorBox: (theme: Theme) => css`
        padding: 16px;
        position: relative;
        border-radius: ${theme.spacing(0.5)}
        border: 1px solid ${theme.palette.divider};
        background-color: ${theme.palette.common.white};
        ${theme.breakpoints.down('md')} {
            padding: 8px 16px;
        }
    `,

    toggleButton: css`
        font-size: 12px;
        text-transform: none;
        &:nth-of-type(1) {
            &.Mui-selected {
                background-color: #ffebe6;
                color: #b22400;
            }
        }
        &:nth-of-type(3) {
            &.Mui-selected {
                background-color: #e8fdf2;
                color: #006b47;
            }
        }
    `,

    questionNumber: (theme: Theme) => css`
        color: #c1c7d0;
        font-size: 12px;
        line-height: 16px;
        ${theme.breakpoints.down('md')} {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    `,

    factorText: (theme: Theme) => css`
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        ${theme.breakpoints.down('md')} {
            justify-content: flex-start;
            max-width: 86%;
        }
    `,

    factorButtons: (theme: Theme) => css`
        display: flex;
        justify-content: center;
        ${theme.breakpoints.down('md')} {
            & button {
                width: 33.33%;
            }
        }
    `,
};

export const SuccessFactors: FC<ISuccessFactorsProps> = ({
    surveyFactors,
    surveyLabelTranslations,
    nextComponent,
}) => {
    const { values, setFieldValue } = useFormikContext<{
        [key: string]: FactorValue | null;
    }>();

    useEffect(() => {
        surveyFactors.length > 0 ? '' : nextComponent();
    }, [nextComponent, surveyFactors]);

    const { t } = useContext(MultiLanguageSupportContext);

    const filterTranslates = filterTranslatesBuilder(surveyLabelTranslations);

    const theme = useTheme();
    const breakpointSm = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box css={useStyles.root}>
            <Container css={useStyles.content}>
                <Box css={useStyles.stepIntro}>
                    <Typography
                        variant="h2"
                        align="center"
                        css={useStyles.stepHeading}
                    >
                        {filterTranslates(
                            'successFactorHeadline',
                            t('success-factor-headline')
                        )}
                    </Typography>
                </Box>
                <Container css={useStyles.factorsContainer}>
                    <Grid container spacing={2}>
                        {surveyFactors.map((factor, idx) => (
                            <Grid item xs={12} sm={6} md={4} key={idx}>
                                <Box css={useStyles.factorBox}>
                                    <Typography
                                        css={useStyles.questionNumber}
                                        align="right"
                                    >
                                        {breakpointSm
                                            ? `${idx + 1} / ${
                                                  surveyFactors.length
                                              }`
                                            : `${idx + 1}`}
                                    </Typography>
                                    <Box css={useStyles.factorText}>
                                        <Box mr={2}>
                                            <FactorIcon {...factor} />
                                        </Box>
                                        <Typography
                                            variant="h6"
                                            color="textSecondary"
                                        >
                                            {factor.title}
                                        </Typography>
                                    </Box>
                                    <ToggleButtonGroup
                                        css={useStyles.factorButtons}
                                        exclusive
                                        size="small"
                                        value={values[factor.id]}
                                        onChange={(_e, value) => {
                                            if (value !== null) {
                                                setFieldValue(factor.id, value);
                                            }
                                        }}
                                    >
                                        <ToggleButton
                                            value="improvable"
                                            css={useStyles.toggleButton}
                                        >
                                            {filterTranslates(
                                                'improveHTML',
                                                t('improve-html')
                                            )}
                                        </ToggleButton>
                                        <ToggleButton
                                            value="neutral"
                                            css={useStyles.toggleButton}
                                        >
                                            {filterTranslates(
                                                'neutralHTML',
                                                t('neutral-html')
                                            )}
                                        </ToggleButton>
                                        <ToggleButton
                                            value="great"
                                            css={useStyles.toggleButton}
                                        >
                                            {filterTranslates(
                                                'greatHTML',
                                                t('great-html')
                                            )}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Container>
        </Box>
    );
};
