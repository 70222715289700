import { useSession } from 'core/hooks/session.hook';
import { ROUTES } from 'modules/navigation/enums/routes.enum';
import { FC, ReactNode } from 'react';
import { Navigate, Route, RouteProps, Routes } from 'react-router-dom';
import { AbilityContext } from '../Can';
import { Layout } from 'core/components/Layout';

export interface ILocationState {
    from: {
        pathname: string;
        search: string;
    };
}

interface PrivateRouteProps {
    path?: RouteProps['path'];
    component?: React.ElementType;
    children: ReactNode;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
    const { isLoggedIn, abilities } = useSession();

    const locationState: ILocationState = {
        from: {
            pathname: location.pathname,
            search: location.search,
        },
    };

    if (isLoggedIn && children) {
        return (
            <AbilityContext.Provider value={abilities}>
                <Routes>
                    <Route path="*" element={<Layout />} />
                </Routes>
            </AbilityContext.Provider>
        );
    }

    if (!isLoggedIn) {
        return <Navigate to={ROUTES.LOGIN} state={locationState} replace />;
    }

    return (
        <AbilityContext.Provider value={abilities}>
            <Routes>
                {isLoggedIn && children && <Route element={<Layout />} />}

                {!isLoggedIn && (
                    <Navigate to={ROUTES.LOGIN} state={locationState} replace />
                )}
            </Routes>
            {/* <Route
                {...rest}
                render={(routeProps) => {
                    return isLoggedIn ? (
                        children ?? <Component {...routeProps} />
                    ) : (
                        <Navigate
                            to={ROUTES.LOGIN}
                            state={locationState}
                            replace
                        />
                    );
                }}
            /> */}
        </AbilityContext.Provider>
    );
};
