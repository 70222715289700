import {
    defaultLanguage,
    supportedLanguages,
} from 'core/constants/supported-language';
import { LocalStorageKeys } from 'core/enums/local-storage-keys.enum';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const lng = localStorage.getItem(LocalStorageKeys.appLang) || defaultLanguage;

i18n.use(initReactI18next)
    .use(Backend)
    .init({
        lng,
        fallbackLng: defaultLanguage,
        supportedLngs: supportedLanguages,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
