import { Box, Button, Theme, Typography } from '@mui/material';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FC, useContext } from 'react';
import css from 'styles/material-ui/cssVars';
import { EntityListPagination } from '../../../core/components/EntityListPagination';
import { WithCurrentPageListener } from '../../../core/hocs/WithListContainer';
import { WithProvider } from '../../../core/hocs/WithProvider';
import { ActivityDialogContext } from '../components/ActivityDialogProvider';
import { ActivityList } from '../components/ActivityList';
import { activityQuery } from '../state/activity.query';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    marginBottomHeader: {
        marginBottom: '64px',
    },
    posPadding: {
        padding: '20px 10px',
    },
    actions: {
        backgroundColor: css.extraColors.text.darkPrimary,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(3),
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
}));

const ActivityPageContainer: FC = () => {
    const { subtitle, closeModal } = useContext(ActivityDialogContext);

    const classes = useStyles();
    const { t } = useContext(MultiLanguageSupportContext);
    return (
        <>
            <div className={classes.marginBottomHeader}>
                <Typography variant="h1" align="center">
                    {/* TODO: add translation */}
                    Activities
                </Typography>
                <Typography
                    variant="body1"
                    align="center"
                    className={classes.posPadding}
                >
                    {subtitle}
                </Typography>

                <ActivityList />
                <br />
                <EntityListPagination />
            </div>
            <Box
                display="flex"
                justifyContent="space-between"
                className={classes.actions}
            >
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="large"
                    onClick={closeModal}
                >
                    {t('close')}
                </Button>
            </Box>
        </>
    );
};

export const ActivityPage = WithProvider(
    WithCurrentPageListener(ActivityPageContainer),
    activityQuery
);
