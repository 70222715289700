
import { lazy } from 'react';
import { ROUTES } from '../enums/routes.enum';
import { INavigationItem } from '../interfaces/navigationItem.interface';

const PageNotFound = lazy(() => import('core/pages/PageNotFound'));
const ProjectsPageConatiner = lazy(() => import('modules/projects/pages/ProjectsPage'));
const AccountsSettingsPage = lazy(() => import('../../accounts-settings/pages/AccountsSettingsPage'));
const AnalyticsPage = lazy(() => import('../../analytics/Analytics'));
const ProjectPage = lazy(() => import('../../projects/pages/ProjectPage'));
const SinglePulsePage = lazy(() => import('../../pulse/pages/SinglePulsePage'));
const SuperAdminPage = lazy(() => import('../../super-admin/pages/SuperAdminPage'));

export const navigationItems: INavigationItem[] = [
    {
        path: ROUTES.PROJECT,
        contentComponent: ProjectPage,
    },
    {
        path: ROUTES.PULSE,
        contentComponent: SinglePulsePage,
    },
    {
        path: ROUTES.PROJECTS,
        contentComponent: ProjectsPageConatiner,
    },
    {
        path: ROUTES.SUPER_ADMIN,
        contentComponent: SuperAdminPage,
    },
    {
        path: ROUTES.ANALYTICS,
        contentComponent: AnalyticsPage,
    },
    {
        path: ROUTES.ACCOUNT_SETTINGS,
        contentComponent: AccountsSettingsPage,
    },
    {
        path: ROUTES.NOT_FOUND,
        contentComponent: PageNotFound,
    },
];
