import { createContext, ReactNode, useState } from 'react';

type Theme = 'light' | 'dark';

const ThemeSetterContext = createContext<[Theme, (theme: Theme) => void]>([
    'light',
    () => {
        // console.log(theme);
    },
]);

const ThemeSetterProvider = ({ children }: { children: ReactNode }) => (
    <ThemeSetterContext.Provider value={useState<Theme>('light')}>
        {children}
    </ThemeSetterContext.Provider>
);

export { ThemeSetterProvider, ThemeSetterContext };
