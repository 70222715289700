import {
    EntityState,
    EntityStore,
    getEntityType,
    getIDType,
} from '@datorama/akita';
import { AxiosResponse } from 'axios';
import { ICreateEntityOptions } from 'core/interfaces/create-entity-options.interface';
import { IUpdateEntityOptions } from 'core/interfaces/update-entity-options.interface';
import { CrudApiService } from 'core/services/crudApiService';
import { useContext } from 'react';
import {
    EntitiesContext,
    IEntitiesContext,
} from '../constants/entities.context';
import { IDeleteEntitiesOptions } from '../interfaces/delete-entities-options.interface';
import { IEntity } from '../interfaces/entity.interface';
import { EntitiesStore, IEntityUIState } from '../state/entities.store';

interface ISetListSlugOptions {
    prefix?: string;
    slug?: string;
    suffix?: string;
}

const setListSlug = <T extends IEntity, R>(store: EntitiesStore<T, R>) => ({
    prefix,
    slug,
    suffix = '',
}: ISetListSlugOptions) => {
    store.listSlug = `${prefix ? prefix + '/' : ''}${slug ?? store.entitiesSlug
        }${suffix}`;
};

const deleteSelected = <T extends IEntity, R>({
    query,
    store,
}: IEntitiesContext<T, R>) => async (
    options?: Partial<IDeleteEntitiesOptions>
) => {
        const selectedIds = query.getActiveId();
        selectedIds && (await store.deleteMultiple(selectedIds, options));
    };

const archiveSingle = <T extends IEntity, R>(store: EntitiesStore<T, R>) => (
    entitiy: T,
    options?: Partial<IDeleteEntitiesOptions>
) => store.archiveEntity(entitiy, options);

const restoreSingle = <T extends IEntity, R>(store: EntitiesStore<T, R>) => (
    entitiy: T,
    options?: Partial<IDeleteEntitiesOptions>
) => store.restoreEntity(entitiy, options);

export const fetchById = <
    T extends IEntity,
    R,
    S extends EntityState<T> = EntityState<T>
>(
    store: EntityStore<S>,
    crudApiService: CrudApiService<getEntityType<S>, R>
) => async (id: getIDType<S>): Promise<getEntityType<S> | null> => {
    try {
        const entity = await crudApiService.read(id);

        store.upsert(id, entity);

        return entity;
    } catch (e: any) {
        if (e instanceof Response) {
            throw e;
        }

        // console.log(store.storeName, 'fetchById', e);
    }

    return null;
};

export const getById = <T extends IEntity, R>({
    query,
    store,
}: IEntitiesContext<T, R>) => async (id: string) => {
    let entity = query.getEntity(id);

    if (!entity) {
        entity = (await fetchById(store, store.crudApiService)(id)) as T;
    }

    return entity;
};

export interface IUseCrud<T, R = any> {
    setListSlug: (options: ISetListSlugOptions) => void;
    fetch: (
        params?: IEntityUIState,
        responseHandler?: (res: AxiosResponse) => R[],
        skipSetCurrentPageEntities?: boolean
    ) => Promise<T[] | null>;
    fetchById: (id: string) => Promise<T | null>;
    addMultiple: (entities: T[]) => void;
    deleteSingle: (
        id: string,
        options?: Partial<IDeleteEntitiesOptions>
    ) => Promise<void>;
    deleteMultiple: (
        ids: string[],
        options?: Partial<IDeleteEntitiesOptions>
    ) => Promise<void>;
    deleteSelected: (
        options?: Partial<IDeleteEntitiesOptions>
    ) => Promise<void>;
    archiveSingle: (
        entity: T,
        options?: Partial<IDeleteEntitiesOptions>
    ) => Promise<T | null>;
    restoreSingle: (
        entity: T,
        options?: Partial<IDeleteEntitiesOptions>
    ) => Promise<T | null>;
    updateSingle: (
        entity: T,
        options?: Partial<IUpdateEntityOptions>
    ) => Promise<T | null>;
    createSingle: (
        entity: Omit<T, 'id'>,
        options?: Partial<ICreateEntityOptions>
    ) => Promise<T | null>;
    getById: (id: string) => Promise<T>;
}

export const useCrud = <T extends IEntity>(): IUseCrud<T> => {
    const { query, store } = useContext(EntitiesContext);

    return {
        setListSlug: setListSlug(store),

        fetch: store.fetchEntities as (
            params?: IEntityUIState
        ) => Promise<T[] | null>,

        addMultiple: (x) => store.add(x),

        deleteSingle: store.deleteById,

        deleteMultiple: store.deleteMultiple,

        deleteSelected: deleteSelected({ query, store }),

        archiveSingle: archiveSingle(store) as (
            entity: T,
            options?: Partial<IDeleteEntitiesOptions>
        ) => Promise<T | null>,

        restoreSingle: restoreSingle(store) as (
            entity: T,
            options?: Partial<IDeleteEntitiesOptions>
        ) => Promise<T | null>,

        createSingle: store.createEntity as (
            entity: Omit<T, 'id'>,
            options?: Partial<ICreateEntityOptions>
        ) => Promise<T | null>,

        updateSingle: store.updateEntity as (
            entity: T,
            options?: Partial<IUpdateEntityOptions>
        ) => Promise<T | null>,

        fetchById: fetchById(store, store.crudApiService) as (
            id: string
        ) => Promise<T | null>,

        getById: getById({ query, store }) as (id: string) => Promise<T>,
    };
};
