/** @jsxImportSource @emotion/react */
import { Box, Button, Container, css, Dialog, Typography } from '@mui/material';
import { MultiLanguageSupportContext } from 'core/components/MultiLanguageSupportProvider';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoWithText from 'styles/material-ui/elements/logo-with-text';
import { ROUTES } from '../../navigation/enums/routes.enum';

const styles = {
    root: css`
        background-color: #f2ebff;
        height: 100vh;
    `,
    paper: css`
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    form: css`
        width: 100%;
    `,
    container: css`
        background-color: #ffffff;
        padding: 30px;
        flex-direction: column;
        align-items: center;
    `,
};

export const SuccessPassword: FC = () => {
    const { t } = useContext(MultiLanguageSupportContext);
    const navigate = useNavigate();

    return (
        <div css={styles.root}>
            <Dialog
                open={true}
                slotProps={{
                    backdrop: {
                        style: { backgroundColor: '#F4E7FE' },
                    },
                }}
            >
                <Container css={styles.container} maxWidth="sm">
                    <div css={styles.paper}>
                        <LogoWithText />

                        <Box mt={9}>
                            <Typography variant="h4" align="center">
                                {`${t('your-new-pass')}`}
                            </Typography>
                        </Box>
                        <Box mt={9} mb={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={() =>
                                    navigate({ pathname: ROUTES.LOGIN })
                                }
                            >
                                {t('go-to-login-page')}
                            </Button>
                        </Box>
                    </div>
                </Container>
                {/* <NeedHelpMail /> */}
            </Dialog>
        </div>
    );
};
