import CategoriesTreeView from 'core/components/mock/accordions/categoriesTree';
import { Container } from '@mui/material';
import { FC } from 'react';

export const ToBeDefined: FC = () => {
    return (
        <div>
            {/* <h3>Stepper:</h3>
            <StepperComponent
                activeStep={1}
                steps={[
                    { title: 'Step 1', description: 'Info Text' },
                    { title: 'Step 2', description: 'Another Info Text' },
                ]}
            />
            <h3>Empty state screen mock:</h3>
            <PulseEmptyState />
            <h3>Textfields variants:</h3>
            <PulseTextfield />
            <h3>Select Variants:</h3>
            <PulseSelect />
            <h3>Textarea example</h3>
            <PulseTextarea />
            <h3>Poject Card:</h3>
            <ProjectCard />
            <h3>Account locked mock:</h3>
            <p>uncomment in: src/core/pages/ToBeDefined.tsx</p> */}
            {/* <AccountLocked /> */}
            {/* <h3>Pulse Card:</h3>
            <PulseCard /> */}
            {/* <PulseSingle /> */}

            {/* <h3>Buttons</h3>
            <PulseButtons /> */}

            {/* <h3>help and feedback (use as dialog content):</h3>
            <HelpFeedbackModalContent /> */}

            <h3>Categories Tree view:</h3>
            <Container>
                <CategoriesTreeView />
            </Container>

            {/* pulse survey: */}
            {/* <StarRating /> */}
            {/* <SuccessFactors /> */}
            {/* <SurveyFeedback /> */}
            {/* <SurveyOverview /> */}
        </div>
    );
};
