// polyfills
import 'core-js';

import {
    akitaDevtools,
    persistState,
    PersistStateParams,
} from '@datorama/akita';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { MultiLanguageSupportProvider } from 'core/components/MultiLanguageSupportProvider';
import { GeneralDialogProvider } from 'core/components/shared/GeneralDialog/GeneralDialogProvider';
import { lazy, StrictMode, Suspense } from 'react';

import theme from 'styles/material-ui/theme';
import { App } from './App';
import './core/utils/multi-language-support';
import './index.css';
import { reportWebVitals } from './reportWebVitals';

import { createRoot } from 'react-dom/client';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
const IconPickerProvider = lazy(
    () => import('./core/components/IconPickerProvider')
);

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

// import AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';
// import LocalizationProvider from '@mui/x-date-pickers/LocalizationProvider';

const persistStateParams: Partial<PersistStateParams> = {
    include: ['session'],
};

if (import.meta.env.NODE_ENV !== 'production') {
    akitaDevtools();
} else {
    // persistStateParams.preStorageUpdateOperator = () => debounceTime(2000);
}

persistState(persistStateParams);

/**
 * Issue: https://www.gitmemory.com/issue/datorama/akita/634/789712235
 *
 * workaround is to define the resettable flag at store declaration via decorator
 */
// akitaConfig({ resettable: true });

/**
 * We need to wrap app with Suspense because of the translation support,
 * as it loads translation files asynchronously
 */

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <StrictMode>
        <Suspense fallback={null}>
            <IconPickerProvider>
                <MultiLanguageSupportProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <GeneralDialogProvider>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <CssBaseline />
                                    <App />
                                </LocalizationProvider>
                            </GeneralDialogProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </MultiLanguageSupportProvider>
            </IconPickerProvider>
        </Suspense>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
