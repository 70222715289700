export { };

declare global {
    interface Navigator {
        connection?: NetworkInformation;
        mozConnection?: NetworkInformation;
        webkitConnection?: NetworkInformation;
    }

    interface NetworkInformation {
        effectiveType: string;
        downlink: number;
        rtt: number;
        saveData: boolean;
        addEventListener?: (type: string, listener: EventListenerOrEventListenerObject) => void;
    }
}



export const checkNetwork = (): 'offline' | 'slow' | 'good' | 'average' | 'unknown' => {
    const isOnline = navigator.onLine;

    let connectionQuality: 'good' | 'average' | 'poor' | 'metered' | 'unknown' = 'unknown';

    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    if (connection) {
        const { effectiveType, saveData } = connection;

        if (effectiveType === '4g') {
            connectionQuality = 'good';
        } else if (effectiveType === '3g') {
            connectionQuality = 'average';
        } else if (effectiveType === '2g' || effectiveType === 'slow-2g') {
            connectionQuality = 'poor';
        }

        if (saveData) {
            connectionQuality = 'metered';
        }
    }

    if (!isOnline) {
        return 'offline'
    } else if (connectionQuality === 'poor' || connectionQuality === 'metered') {
        return 'slow'
    } else if (connectionQuality === 'average') {
        return 'average'
    } else if (connectionQuality === 'good') {
        return 'good'
    }

    return 'unknown'
};

